import * as yup from "yup";

import { SEARCH_KEYWORDS_DATA_SOURCES } from "src/constants";

const trackerKeywordsDataSourcesSchema = yup
  .string<Search.KeywordsDataSource>()
  .oneOf([...SEARCH_KEYWORDS_DATA_SOURCES])
  .required();

export const trackerSchema = yup
  .object({
    authorId: yup.string().required(),
    companyId: yup.string().required(),

    searchIds: yup.array(yup.string().default("")).default([]),
    keywordsDataSources: yup.array(trackerKeywordsDataSourcesSchema).required(),

    category: yup.string().required(),
    description: yup.string().default(""),

    name: yup.string().default(""),
    tags: yup.array(yup.string().default("")).default([]),

    createdAt: yup.string().default(""),
    updatedAt: yup.string().default(""),
  })
  .noUnknown()
  .required();

const trackerSuggestedCategorySchema = yup
  .object({
    subject: yup.string().required(),
    category: yup.string().required(),
    description: yup.string().required(),
  })
  .required();

export const trackerSuggestedCategoriesSchema = yup
  .array(trackerSuggestedCategorySchema)
  .required();

const suggestedTrackerSchema = yup
  .object({
    subject: yup.string().required(),
    category: yup.string().required(),
    description: yup.string().required(),
  })
  .required();

export const suggestedTrackersSchema = yup
  .array(suggestedTrackerSchema)
  .required();

export const duplicatedDashboardTrackersSchema = yup.object({
  trackerIds: yup.array(yup.string().required()).required(),
});

export const createTrackersWithSearchesSchema = yup.object({
  trackerIds: yup.array(yup.string().required()).required(),
});

export const trackerDescriptionSchema = yup.string().default("");

export type TrackerCategoriesSchemaType = yup.InferType<
  typeof trackerSuggestedCategoriesSchema
>;

export type SuggestedTrackersSchemaType = yup.InferType<
  typeof suggestedTrackersSchema
>;

export type CreateTrackersWithSearchesSchemaType = yup.InferType<
  typeof createTrackersWithSearchesSchema
>;
