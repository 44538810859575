import { useState, useMemo } from "react";
import uniq from "lodash/uniq";
import { useTranslation } from "react-i18next";

import { showDevelopmentError, showToastNotification } from "src/utils";
import { getTrackerDescription } from "src/store/trackers/trackersApi";

export const useGetTrackerDescription = ({
  tracker,
  location,
  selectedTrackers,
}: {
  tracker: Tracker.CreationData;
  location?: Location.Data;
  selectedTrackers: Tracker.CreationData[];
}) => {
  const { t } = useTranslation();

  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>("idle");

  const defaultExcludedDescriptions = useMemo<
    Tracker.CreationData["description"][]
  >(() => {
    const selectedTrackersDescriptions = selectedTrackers.map(
      ({ description }) => description,
    );

    return [...selectedTrackersDescriptions, tracker.description].filter(
      Boolean,
    );
  }, [selectedTrackers, tracker.description]);

  const [excludedDescriptions, setExcludedDescriptions] = useState<
    Tracker.CreationData["description"][]
  >(defaultExcludedDescriptions);

  const _getTrackerDescription = async (
    callback?: (description: Tracker.CreationData["description"]) => void,
  ): Promise<Tracker.CreationData["description"]> => {
    if (!location) return "";

    try {
      setLoadingStatus("loading");

      const description = await getTrackerDescription(
        tracker,
        location,
        excludedDescriptions,
      );

      callback?.(description);

      setLoadingStatus("succeeded");

      setExcludedDescriptions((state) =>
        uniq([...state, description || ""].filter(Boolean)),
      );

      return description;
    } catch (error) {
      showDevelopmentError({ error });

      showToastNotification({
        type: "error",
        text: t("common.error.server_error"),
      });

      callback?.("");

      setLoadingStatus("failed");

      return "";
    }
  };

  return {
    loadingStatus,
    getTrackerDescription: _getTrackerDescription,
  };
};
