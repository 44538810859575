import { FC } from "react";
import { CSSTransition } from "react-transition-group";
import { CSSTransitionProps } from "react-transition-group/CSSTransition";

type Props = Optional<CSSTransitionProps, "addEndListener">;

export const Transition: FC<Props> = ({
  children,
  unmountOnExit = true,
  addEndListener = () => {},
  ...props
}) => (
  <CSSTransition
    {...props}
    unmountOnExit={unmountOnExit}
    addEndListener={addEndListener}
  >
    {children}
  </CSSTransition>
);
