import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "./SuggestedSearches.module.scss";
import { generateDocId } from "src/store/utils";
import { Button, Preloader } from "src/components";
import { getLocationDefaultLanguageId } from "src/utils";
import { ChevronDown, PencilOutline, Stars } from "src/assets/icons";
import { useGetSuggestedSearches } from "src/pages/Trackers/CreateTrackers/hooks";
import {
  SEARCH_DEFAULT_STATUS,
  SEARCH_DEFAULT_DESCRIPTION,
} from "src/constants";
import {
  AddSearchModal,
  CountriesDropdown,
  LanguagesDropdown,
} from "src/features";
import {
  selectLanguageById,
  selectLocationById,
  selectLocationsByKeywordsDataSource,
  selectLanguagesByKeywordsDataSource,
} from "src/store/selectors";
import {
  useModal,
  useLanguageId,
  useLocationId,
  useUnmountEffect,
} from "src/hooks";

// Inner imports
import { SuggestedSearch } from "./components";

type Props = {
  isExpanded?: boolean;
  tracker: Tracker.CreationData | Tracker.Data;
  languageId: Language.Data["id"];
  locationId: Location.Data["id"];
  selectedSearches: Search.CreationData[];
  keywordsDataSource: Search.KeywordsDataSource;
  selectSearchHandler: (value: Search.CreationData) => void;
  unselectSearchHandler: (value: Search.CreationData) => void;
};

export const SuggestedSearches: FC<Props> = ({
  tracker,
  selectedSearches,
  keywordsDataSource,
  selectSearchHandler,
  unselectSearchHandler,
  locationId: defaultLocationId,
  languageId: defaultLanguageId,
  isExpanded: defaultIsExpanded = true,
}) => {
  const { t } = useTranslation();

  const { setModal } = useModal();

  const [locationId, setLocationId] = useLocationId({
    keywordsDataSource,
    locationId: defaultLocationId,
  });

  const [languageId, setLanguageId] = useLanguageId({
    locationId,
    keywordsDataSource,
    languageId: defaultLanguageId,
  });

  const locations = useSelector((state: Store.RootState) =>
    selectLocationsByKeywordsDataSource(state, keywordsDataSource),
  );

  const languages = useSelector((state: Store.RootState) =>
    selectLanguagesByKeywordsDataSource(state, keywordsDataSource),
  );

  const location = useSelector((state: Store.RootState) =>
    selectLocationById(state, locationId),
  );

  const language = useSelector((state: Store.RootState) =>
    selectLanguageById(state, languageId),
  );

  const [suggestedSearches, setSuggestedSearches] = useState<
    Search.CreationData[]
  >([]);

  const [excludedSearches, setExcludedSearches] = useState<
    Search.CreationData[]
  >([]);

  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>("idle");

  const [isExpanded, setIsExpanded] = useState<boolean>(defaultIsExpanded);

  const { getSuggestedSearches, cancelGetSuggestedSearches, isLoading } =
    useGetSuggestedSearches({
      status: loadingStatus,
      updateStatusHandler: setLoadingStatus,
      updateExcludedSearchesHandler: setExcludedSearches,
    });

  const locationName = useMemo<string>(
    () => location?.name || "",
    [location?.name],
  );

  const languageName = useMemo<string>(
    () => language?.name || "",
    [language?.name],
  );

  const hasSuggestedSearchesAutoFetch = useMemo<boolean>(
    () =>
      Boolean(tracker?.category) &&
      !suggestedSearches.length &&
      loadingStatus === "idle",
    [loadingStatus, suggestedSearches.length, tracker?.category],
  );

  const SuggestedSearches = useMemo<JSX.Element>(() => {
    switch (true) {
      case Boolean(suggestedSearches.length):
        return (
          <>
            {isLoading && (
              <div className={styles.loader}>
                <Preloader
                  type="bar"
                  text={t(
                    "page.create_tracker.select_searches.loader.suggest_searches",
                  )}
                />
              </div>
            )}
            {suggestedSearches.map((search) => (
              <SuggestedSearch
                key={search.id}
                search={search}
                selectedSearches={selectedSearches}
                selectSearchHandler={selectSearchHandler}
                unselectSearchHandler={unselectSearchHandler}
              />
            ))}
          </>
        );
      case isLoading:
        return (
          <Preloader
            type="bar"
            className={styles.loader}
            text={t(
              "page.create_tracker.select_searches.loader.suggest_searches",
            )}
          />
        );
      default:
        return (
          <div className={styles.placeholder}>
            {t(
              "page.create_tracker.select_searches.placeholder.suggested_searches",
            )}
          </div>
        );
    }
  }, [
    t,
    isLoading,
    selectedSearches,
    suggestedSearches,
    selectSearchHandler,
    unselectSearchHandler,
  ]);

  const updateSuggestedSearches = useCallback(
    ({
      locationId: newLocationId,
      languageId: newLanguageId,
    }: {
      locationId?: Location.Data["id"];
      languageId?: Language.Data["id"];
    }): Promise<Search.CreationData[]> => {
      const isConfigurationUpdated = Boolean(newLocationId || newLanguageId);

      const newExcludedSearches = isConfigurationUpdated
        ? [...selectedSearches]
        : [...excludedSearches, ...selectedSearches, ...suggestedSearches];

      return getSuggestedSearches({
        keywordsDataSource,
        query: tracker?.name || "",
        callback: setSuggestedSearches,
        category: tracker?.category || "",
        locationId: newLocationId || locationId,
        languageId: newLanguageId || languageId,
        description: tracker?.description || "",
        excludedSearches: newExcludedSearches,
      });
    },
    [
      locationId,
      languageId,
      tracker?.name,
      excludedSearches,
      selectedSearches,
      tracker?.category,
      suggestedSearches,
      keywordsDataSource,
      tracker?.description,
      getSuggestedSearches,
    ],
  );

  useUnmountEffect(cancelGetSuggestedSearches);

  useEffect(() => {
    if (hasSuggestedSearchesAutoFetch) updateSuggestedSearches({}).catch();
  }, [hasSuggestedSearchesAutoFetch, updateSuggestedSearches]);

  const onLocationIdChange = (value: Location.Data["id"]): void => {
    if (value === locationId) return;

    setLocationId(value);

    const languageId = getLocationDefaultLanguageId(
      value,
      locations,
      languages,
    );

    updateSuggestedSearches({ locationId: value, languageId }).catch();
  };

  const onLanguageIdChange = (value: Language.Data["id"]): void => {
    if (value === languageId) return;

    setLanguageId(value);

    updateSuggestedSearches({ languageId: value }).catch();
  };

  const addSearch = (value: Search.CreationData["subject"]): void =>
    selectSearchHandler({
      languageId,
      locationId,
      subject: value,
      keywordsDataSource,
      id: generateDocId(),
      status: SEARCH_DEFAULT_STATUS,
      description: SEARCH_DEFAULT_DESCRIPTION,
    });

  const openAddSearchModal = (): void =>
    setModal(<AddSearchModal submitHandler={addSearch} />);

  const onExpandClick = (): void => setIsExpanded(true);

  if (!isExpanded)
    return (
      <div className={styles.expandWrapper}>
        <div className={styles.expandButtonWrapper}>
          <Button
            buttonSize="small"
            onClick={onExpandClick}
            buttonStyle="transparent"
            className={styles.expandButton}
          >
            <span>{t("page.create_tracker.select_searches.label.expand")}</span>
            <ChevronDown />
          </Button>
        </div>
      </div>
    );

  return (
    <div className={styles.wrapper}>
      <div className={styles.section}>
        <div className={styles.title}>
          <Stars />
          <span className={styles.heading}>
            {t("page.create_tracker.select_searches.label.suggested_searches")}
          </span>
        </div>
        <div className={styles.settings}>
          <div title={locationName}>
            <CountriesDropdown
              isDisabled={isLoading}
              locationId={locationId}
              setLocationId={onLocationIdChange}
              keywordsDataSource={keywordsDataSource}
            />
          </div>
          <div title={languageName}>
            <LanguagesDropdown
              isDisabled={isLoading}
              languageId={languageId}
              setLanguageId={onLanguageIdChange}
              keywordsDataSource={keywordsDataSource}
            />
          </div>
          <div className={styles.addButton}>
            <Button buttonStyle="outlined" onClick={openAddSearchModal}>
              <span>
                {t("page.create_tracker.select_searches.button.add_manually")}
              </span>
              <PencilOutline />
            </Button>
          </div>
        </div>
      </div>
      <div className={styles.section}>
        <div className={styles.trackers}>{SuggestedSearches}</div>
      </div>
    </div>
  );
};
