import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "./SelectedSearch.module.scss";
import { useModal } from "src/hooks";
import { useAppDispatch } from "src/store";
import { MenuDropdown, Preloader } from "src/components";
import { MenuOption } from "src/components/MenuDropdown/types";
import { getSearchKeywords } from "src/store/searches/searchesApi";
import { createSearch, fetchSearchConfigurationById } from "src/store/actions";
import { useSearchStatusObserver } from "src/features/TrackerPageComponents/SelectedSearchesSection/hooks";
import {
  EditSearchModal,
  DuplicateSearchModal,
  SearchKeywordsDataSourceIcon,
} from "src/features";
import {
  selectLocationById,
  selectLanguageById,
  selectSearchConfigurationById,
} from "src/store/selectors";
import {
  getKeywordSearchLink,
  showToastNotification,
  isSearchCreatedTypeGuard,
} from "src/utils";

type Props = {
  keywordsData?: Search.KeywordsData;
  search: Search.Data | Search.CreationData;
  openKeywordsHandler: (search: Search.CreationData) => void;
  selectSearchHandler: (search: Search.CreationData) => void;
  unselectSearchHandler: (search: Search.CreationData) => void;
  updateSearchHandler: ({
    id,
    changes,
  }: {
    id: Search.Data["id"];
    changes: Search.CreationData;
  }) => void;
  updateSearchKeywordsHandler: (
    searchId: Search.Data["id"],
    keywords: Search.Keyword[],
    status: LoadingStatus,
  ) => void;
};

export const SelectedSearch: FC<Props> = ({
  search,
  keywordsData,
  openKeywordsHandler,
  updateSearchHandler,
  selectSearchHandler,
  unselectSearchHandler,
  updateSearchKeywordsHandler,
}) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { setModal } = useModal();

  const searchId = useMemo<Search.Data["id"]>(() => search.id, [search]);

  const location = useSelector((state: Store.RootState) =>
    selectLocationById(state, search.locationId),
  );

  const language = useSelector((state: Store.RootState) =>
    selectLanguageById(state, search.languageId),
  );

  const searchConfiguration = useSelector((state: Store.RootState) =>
    selectSearchConfigurationById(state, searchId),
  );

  const [searchCreateLoadingStatus, setSearchCreateLoadingStatus] =
    useState<LoadingStatus>("idle");

  const { searchStatus } = useSearchStatusObserver(search);

  const locationName = useMemo<Location.Data["name"]>(
    () => location?.name || "",
    [location?.name],
  );

  const languageName = useMemo<Language.Data["name"]>(
    () => language?.name || "",
    [language?.name],
  );

  const isSearchCreateLoading = useMemo<boolean>(
    () => searchCreateLoadingStatus === "loading",
    [searchCreateLoadingStatus],
  );

  const keywordsStatus = useMemo<LoadingStatus>(
    () => keywordsData?.status || "idle",
    [keywordsData?.status],
  );

  const { searchLink, imageSearchLink } = useMemo(
    () => ({
      searchLink: getKeywordSearchLink({ keyword: search.subject, location }),
      imageSearchLink: getKeywordSearchLink({
        keyword: search.subject,
        location,
        type: "image",
      }),
    }),
    [search.subject, location],
  );

  useEffect(() => {
    if (
      !searchStatus ||
      !isSearchCreatedTypeGuard(search) ||
      search.status === searchStatus
    )
      return;

    updateSearchHandler({
      id: search.id,
      changes: { ...search, status: searchStatus },
    });
  }, [search, searchStatus, updateSearchHandler]);

  useEffect(() => {
    if (
      searchCreateLoadingStatus !== "succeeded" &&
      isSearchCreatedTypeGuard(search)
    )
      setSearchCreateLoadingStatus("succeeded");
  }, [search, searchCreateLoadingStatus]);

  useEffect(() => {
    if (
      searchCreateLoadingStatus !== "idle" ||
      isSearchCreatedTypeGuard(search)
    )
      return;

    setSearchCreateLoadingStatus("loading");

    dispatch(createSearch(search))
      .unwrap()
      .then((newSearch) => {
        updateSearchKeywordsHandler(newSearch.id, [], "idle");

        updateSearchHandler({ id: search.id, changes: newSearch });

        setSearchCreateLoadingStatus("succeeded");
      })
      .catch((error) => {
        console.error(error);

        showToastNotification({
          type: "error",
          text: t("common.error.server_error"),
        });

        setSearchCreateLoadingStatus("failed");

        unselectSearchHandler(search);
      });
  }, [
    t,
    search,
    dispatch,
    updateSearchHandler,
    unselectSearchHandler,
    searchCreateLoadingStatus,
    updateSearchKeywordsHandler,
  ]);

  useEffect(() => {
    if (
      searchConfiguration ||
      searchCreateLoadingStatus !== "succeeded" ||
      !isSearchCreatedTypeGuard(search)
    )
      return;

    if (searchStatus !== "READY" && searchStatus !== "NO_SELECTED_KEYWORDS")
      return;

    dispatch(fetchSearchConfigurationById(search.id))
      .unwrap()
      .catch((error) => {
        console.error(error);

        showToastNotification({
          type: "error",
          text: t("common.error.server_error"),
        });
      });
  }, [
    t,
    search,
    dispatch,
    searchStatus,
    searchConfiguration,
    searchCreateLoadingStatus,
  ]);

  useEffect(() => {
    if (
      !searchConfiguration ||
      keywordsStatus !== "idle" ||
      !isSearchCreatedTypeGuard(search)
    )
      return;

    if (searchStatus !== "READY" && searchStatus !== "NO_SELECTED_KEYWORDS")
      return;

    updateSearchKeywordsHandler(search.id, [], "loading");

    getSearchKeywords(search.id)
      .then((keywords) =>
        updateSearchKeywordsHandler(search.id, keywords, "succeeded"),
      )
      .catch(() => {
        updateSearchKeywordsHandler(search.id, [], "failed");

        showToastNotification({
          type: "error",
          text: t("common.error.server_error"),
        });
      });
  }, [
    t,
    search,
    searchStatus,
    keywordsStatus,
    searchConfiguration,
    searchCreateLoadingStatus,
    updateSearchKeywordsHandler,
  ]);

  const showSearch = useCallback((): void => {
    window.open(searchLink, "_blank");
  }, [searchLink]);

  const showImageSearch = useCallback((): void => {
    window.open(imageSearchLink, "_blank");
  }, [imageSearchLink]);

  const showEditSearchModal = useCallback((): void => {
    if (!isSearchCreatedTypeGuard(search)) return;

    return setModal(
      <EditSearchModal search={search} submitHandler={updateSearchHandler} />,
    );
  }, [search, setModal, updateSearchHandler]);

  const showDuplicateSearchModal = useCallback((): void => {
    if (!isSearchCreatedTypeGuard(search)) return;

    return setModal(
      <DuplicateSearchModal
        search={search}
        duplicateHandler={selectSearchHandler}
      />,
    );
  }, [selectSearchHandler, search, setModal]);

  const openSearchSettings = useCallback((): void => {
    if (!isSearchCreatedTypeGuard(search)) return;

    return openKeywordsHandler(search);
  }, [openKeywordsHandler, search]);

  const unselectSearch = useCallback(
    (): void => unselectSearchHandler(search),
    [search, unselectSearchHandler],
  );

  const options = useMemo<MenuOption[]>(
    () => [
      {
        group: 1,
        icon: "DuplicateOutline",
        onClick: showDuplicateSearchModal,
        label: t("component.selected_search.label.duplicate_search"),
      },
      {
        group: 1,
        icon: "PencilOutline",
        onClick: showEditSearchModal,
        label: t("component.selected_search.label.edit_search"),
      },
      {
        group: 1,
        icon: "GearOutline",
        onClick: openSearchSettings,
        label: t("component.selected_search.label.settings"),
      },
      {
        group: 2,
        icon: "Search",
        onClick: showSearch,
        label: t("component.selected_search.label.search_link"),
      },
      {
        group: 2,
        icon: "ImageSearch",
        onClick: showImageSearch,
        label: t("component.selected_search.label.image_search_link"),
      },
      {
        group: 3,
        type: "danger",
        icon: "TrashOutline",
        onClick: unselectSearch,
        label: t("component.selected_search.label.unselect_search"),
      },
    ],
    [
      t,
      showSearch,
      unselectSearch,
      showImageSearch,
      openSearchSettings,
      showEditSearchModal,
      showDuplicateSearchModal,
    ],
  );

  return (
    <div className={styles.wrapper}>
      {isSearchCreateLoading && (
        <div className={styles.loader}>
          <Preloader
            type="bar"
            text={t("component.selected_search.loader.create_search")}
          />
        </div>
      )}
      <div className={styles.header}>
        <div className={styles.heading}>
          <span>{t("component.selected_search.label.add_search")}</span>
        </div>
        <div className={styles.settings}>
          <MenuDropdown className={styles.setting} options={options} />
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.title}>
          <span>{search.subject}</span>
        </div>
        <div className={styles.description}>
          <span>{search.description}</span>
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.keywordsDataSource}>
          <SearchKeywordsDataSourceIcon
            keywordsDataSource={search.keywordsDataSource}
          />
        </div>
        <div className={styles.location} title={locationName}>
          <span>{locationName}</span>
        </div>
        <div className={styles.language} title={languageName}>
          <span>{search.languageId.toUpperCase()}</span>
        </div>
      </div>
    </div>
  );
};
