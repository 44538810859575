import { FC, useMemo, useState } from "react";
import { useImmer } from "use-immer";
import { useTranslation } from "react-i18next";

import styles from "./SelectedSearchesSection.module.scss";
import { KeywordsSidebar } from "src/features";
import { SelectedSearch } from "src/pages/Trackers/CreateTrackers/components/SelectSearchesSection/components";

type Props = {
  selectedSearches: Array<Search.Data | Search.CreationData>;
  selectSearchHandler: (search: Search.CreationData) => void;
  unselectSearchHandler: (search: Search.CreationData) => void;
  updateSearchHandler: ({
    id,
    changes,
  }: {
    id: Search.Data["id"];
    changes: Search.CreationData;
  }) => void;
};

export const SelectedSearchesSection: FC<Props> = ({
  selectedSearches,
  selectSearchHandler,
  updateSearchHandler,
  unselectSearchHandler,
}) => {
  const { t } = useTranslation();

  const [keywordsSearch, setKeywordsSearch] =
    useState<Search.CreationData | null>(null);

  const defaultKeywords = useMemo<
    Record<Search.Data["id"], Search.KeywordsData>
  >(() => {
    const keywords = new Map<Search.Data["id"], Search.KeywordsData>();

    for (const search of selectedSearches)
      keywords.set(search.id, { keywords: [], status: "idle" });

    return Object.fromEntries(keywords);
  }, [selectedSearches]);

  const [keywords, setKeywords] =
    useImmer<Record<Search.Data["id"], Search.KeywordsData>>(defaultKeywords);

  const hasSelectedSearches = useMemo<boolean>(
    () => Boolean(selectedSearches.length),
    [selectedSearches.length],
  );

  const openSearchKeywords = (search: Search.CreationData): void =>
    setKeywordsSearch(search);

  const closeSearchKeywords = (): void => setKeywordsSearch(null);

  const updateSearchKeywords = (
    searchId: Search.Data["id"],
    keywords: Search.Keyword[],
    status: LoadingStatus,
  ): void =>
    setKeywords((draft) => {
      draft[searchId] = { keywords, status };
    });

  return (
    <div className={styles.wrapper}>
      {keywordsSearch && (
        <KeywordsSidebar
          search={keywordsSearch}
          closeSidebarHandler={closeSearchKeywords}
          keywordsData={keywords[keywordsSearch.id]}
        />
      )}
      {hasSelectedSearches && (
        <div className={styles.section}>
          <div className={styles.searchesWrapper}>
            <span className={styles.subheading}>
              {t("page.create_tracker.configuration.label.selected_searches")}
            </span>
            <div className={styles.searches}>
              {selectedSearches.map((search) => (
                <SelectedSearch
                  key={search.id}
                  search={search}
                  keywordsData={keywords[search.id]}
                  updateSearchHandler={updateSearchHandler}
                  selectSearchHandler={selectSearchHandler}
                  openKeywordsHandler={openSearchKeywords}
                  unselectSearchHandler={unselectSearchHandler}
                  updateSearchKeywordsHandler={updateSearchKeywords}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
