import { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "./KeywordsDataSourcesDropdown.module.scss";
import { Select } from "src/components";
import { SEARCH_KEYWORDS_DATA_SOURCE_MAP } from "src/constants";
import { getIconByName, isKeywordsDataSourceTypeGuard } from "src/utils";
import { selectSubscriptionPlanKeywordsDataSources } from "src/store/selectors";
import {
  SelectOption,
  SelectProps,
} from "src/components/selectors/Select/types";

type Props = Pick<SelectProps, "openingDirection"> & {
  isDisabled?: boolean;
  hasDefaultStyles?: boolean;
  dataSource: Search.KeywordsDataSource;
  setDataSource: (value: Search.KeywordsDataSource) => void;
};

export const KeywordsDataSourcesDropdown: FC<Props> = ({
  dataSource,
  setDataSource,
  isDisabled = false,
  hasDefaultStyles = false,
  openingDirection = "bottom-end",
}) => {
  const { t } = useTranslation();

  const subscriptionPlanKeywordsDataSources = useSelector(
    selectSubscriptionPlanKeywordsDataSources,
  );

  const keywordsDataSourceOptions = useMemo<
    SelectOption<Search.KeywordsDataSource>[]
  >(
    () =>
      subscriptionPlanKeywordsDataSources.map((keywordsDataSource) => ({
        value: keywordsDataSource,
        label: t(SEARCH_KEYWORDS_DATA_SOURCE_MAP[keywordsDataSource].labelKey),
        renderIcon: () =>
          getIconByName(
            SEARCH_KEYWORDS_DATA_SOURCE_MAP[keywordsDataSource].icon,
          ),
      })),
    [subscriptionPlanKeywordsDataSources, t],
  );

  const optionIcon = useMemo<AppIcon>(
    () => SEARCH_KEYWORDS_DATA_SOURCE_MAP[dataSource].icon,
    [dataSource],
  );

  const onDataSourceSelect = (value: string) => {
    if (isKeywordsDataSourceTypeGuard(value)) setDataSource(value);
  };

  return (
    <div className={hasDefaultStyles ? "" : styles.wrapper}>
      <Select
        icon={optionIcon}
        hasFilter={false}
        value={dataSource}
        isDisabled={isDisabled}
        changeHandler={onDataSourceSelect}
        options={keywordsDataSourceOptions}
        openingDirection={openingDirection}
        title={t("component.keywords_data_sources.title")}
        inputClassName={hasDefaultStyles ? "" : styles.input}
        selectClassName={hasDefaultStyles ? "" : styles.select}
        dropdownClassName={hasDefaultStyles ? "" : styles.dropdown}
      />
    </div>
  );
};
