export const LOCALIZATION_DATA = {
  en: {
    translation: {
      //#region COMMON
      "common.error.server_error": "Oops, something went wrong",
      "common.error.server_error_reload":
        "Oops, something went wrong, Please, reload page and try again",
      "common.date_range.one_month": "1 month",
      "common.date_range.three_months": "3 months",
      "common.date_range.six_months": "6 months",
      "common.date_range.one_year": "1 year",
      "common.date_range.type_single": "{{dateRange}} ({{date}})",
      "common.date_range.type_period":
        "{{dateRange}} ({{startDate}} - {{endDate}})",
      //#endregion COMMON
      //#region PAGE
      //#region EXPLORE TREND
      "page.explore_trend.title": "Search for a Market brief",
      "page.explore_trend.loader.search_dashboard": "Finding Market briefs...",
      "page.explore_trend.label.create_dashboard_prefix": "or create",
      "page.explore_trend.label.capabilities": "Capabilities",
      "page.explore_trend.label.search_dashboard":
        "Search for ready-made Market briefs or create your own. Gain insights into market perspectives and trends.",
      "page.explore_trend.label.featured_topics": "Featured topics",
      "page.explore_trend.button.create_dashboard": "Market brief",
      "page.explore_trend.tooltip.create_dashboard": "Create Market brief",
      "page.explore_trend.form.placeholder.search_query":
        "Enter a brand, product or search term",

      //#region SUGGESTED DASHBOARD
      "page.explore_trend.suggested_dashboard.title":
        "Couldn't find what you were looking for? Create new",
      "page.explore_trend.suggested_dashboard.label.company_dashboards":
        "Company Market briefs",
      "page.explore_trend.suggested_dashboard.label.external_dashboards":
        "External Market briefs",
      "page.explore_trend.suggested_dashboard.button.create_dashboard":
        "Market brief",
      "page.explore_trend.suggested_dashboard.tooltip.create_dashboard":
        "Create Market brief",
      "page.explore_trend.suggested_dashboard.label.no_dashboards":
        "No matching Market briefs",
      "page.explore_trend.suggested_dashboard.label.author":
        "Owner: <bold>{{name}}</bold>",
      "page.explore_trend.suggested_dashboard.label.visibility":
        "Visibility: <bold>{{visibility}}</bold>",
      "page.explore_trend.suggested_dashboard.label.included_topics":
        "Included Topics",
      "page.explore_trend.suggested_dashboard.label.topic_metadata":
        "{{category}}, {{location}}",
      "page.explore_trend.suggested_dashboard.loader.download_dashboard":
        "Loading Market brief...",
      "page.explore_trend.suggested_dashboard.status.error.no_dashboards":
        "Your search did mot match any Market briefs.<break/>Update your search or create a new Topic.",
      //#endregion SUGGESTED DASHBOARD
      //#endregion EXPLORE TREND
      //#region SHARE DASHBOARD
      "page.share_dashboard.button.sign_up": "Sign up",
      "page.share_dashboard.label.sign_up":
        "Create an account to save the Market brief?",
      //#endregion SHARE DASHBOARD
      //#region CREATE TRACKER
      //#region ENTER QUERY
      "page.create_tracker.enter_query.heading": "Start exploring",
      "page.create_tracker.enter_query.subheading":
        "Start by asking a question or entering a specific topic that interests you",
      "page.create_tracker.enter_query.form.placeholder.analyse":
        "Analyse anything",
      "page.create_tracker.enter_query.form.button.submit": "Continue",
      "page.create_tracker.enter_query.label.data_source": "Data Source",
      "page.create_tracker.enter_query.label.location": "Region",
      "page.create_tracker.enter_query.label.next_step": "Next up",
      "page.create_tracker.enter_query.label.select_category":
        "Select category",
      //#endregion ENTER QUERY
      //#region SELECT CATEGORY
      "page.create_tracker.select_category.heading": "Select category",
      "page.create_tracker.select_category.form.button.submit": "Continue",
      "page.create_tracker.select_category.loader.suggest_categories":
        "Finding categories...",
      "page.create_tracker.select_category.label.next_step": "Next up",
      "page.create_tracker.select_category.label.select_trackers":
        "Add your topics",
      //#endregion SELECT CATEGORY
      //#region SELECT TRACKERS
      "page.create_tracker.select_trackers.heading": "Add your topics",
      "page.create_tracker.select_trackers.label.suggested_trackers":
        "Suggested topics",
      "page.create_tracker.select_trackers.label.selected_trackers":
        "Added topics",
      "page.create_tracker.select_trackers.label.expand":
        "Expand suggested topics",
      "page.create_tracker.select_trackers.button.add_manually": "Add manually",
      "page.create_tracker.select_trackers.loader.create_dashboard":
        "Creating Topics and Market brief...",
      "page.create_tracker.select_trackers.loader.create_topics":
        "Creating Topics...",
      "page.create_tracker.select_trackers.loader.suggest_trackers":
        "Finding suggestions...",
      "page.create_tracker.select_trackers.form.button.create_dashboard":
        "Create Dashboard",
      "page.create_tracker.select_trackers.form.button.create_trackers":
        "Create Topics",
      "page.create_tracker.select_trackers.placeholder.suggest_trackers":
        "No suggestions",
      //#endregion SELECT TRACKERS
      //#region SELECT SEARCHES
      "page.create_tracker.select_searches.heading": "Add your searches",
      "page.create_tracker.select_searches.label.suggested_searches":
        "Suggested searches",
      "page.create_tracker.select_searches.label.selected_searches":
        "Added searches",
      "page.create_tracker.select_searches.label.expand":
        "Expand suggested searches",
      "page.create_tracker.select_searches.button.add_manually": "Add manually",
      "page.create_tracker.select_searches.loader.suggest_searches":
        "Finding suggestions...",
      "page.create_tracker.select_searches.form.button.submit": "Save changes",
      "page.create_tracker.select_searches.placeholder.suggested_searches":
        "No suggestions",
      //#endregion SELECT SEARCHES
      //#region CONFIGURATION
      "page.create_tracker.configuration.title": "Configure Topic",
      "page.create_tracker.configuration.button.add_search": "Add searches",
      "page.create_tracker.configuration.button.expand_add_search":
        "Enter manually",
      "page.create_tracker.configuration.button.create_tracker": "Save",
      "page.create_tracker.configuration.button.create_dashboard_tracker":
        "Save", // tc_button_save_and_add
      "page.create_tracker.configuration.button.create_tracker_and_dashboard":
        "Save and View",
      "page.create_tracker.configuration.button.cancel": "Cancel",
      "page.create_tracker.configuration.button.navigate_back": "Go back",
      "page.create_tracker.configuration.button.save_tracker": "Save",
      "page.create_tracker.configuration.button.expand_suggested_entity":
        "Enter manually",
      "page.create_tracker.configuration.label.suggested_searches":
        "Suggested Searches to add to Topic",
      "page.create_tracker.configuration.label.no_suggested_searches":
        "No suggestions",
      "page.create_tracker.configuration.label.tracker_category":
        "<bold>Category</bold>: {{category}}",
      "page.create_tracker.configuration.label.tracker_location":
        "<bold>Region</bold>: {{location}}",
      "page.create_tracker.configuration.label.final_step":
        "Final step to create a Market brief",
      "page.create_tracker.configuration.label.no_tracker_name":
        "Cannot create Topic with an empty name",
      "page.create_tracker.configuration.label.selected_searches":
        "Added searches",
      "page.create_tracker.configuration.label.select_suggested_search":
        "Add Searches to Topic",
      "page.create_tracker.configuration.tooltip.edit_tracker":
        "Edit and update search suggestions",
      "page.create_tracker.configuration.tooltip.create_tracker": "Save Topic",
      "page.create_tracker.configuration.tooltip.create_tracker_and_dashboard":
        "Save and view Market brief",
      "page.create_tracker.configuration.tooltip.create_tracker_and_competitor":
        "Save and add to Market brief",
      "page.create_tracker.configuration.loader.download_suggested_searches":
        "Finding suggestions...",
      "page.create_tracker.configuration.loader.create_tracker_and_dashboard":
        "Creating Topic and Market brief...",
      "page.create_tracker.configuration.loader.create_tracker":
        "Creating Topic...",
      "page.create_tracker.configuration.form.validation.name_required":
        "Required",
      "page.create_tracker.configuration.status.success.tracker_created":
        "{{name}} Topic created",
      //#endregion CONFIGURATION
      //#endregion CREATE TRACKER
      //#region EDIT TRACKER
      "page.edit_tracker.title": "Edit Topic",
      "page.edit_tracker.tooltip.edit_configuration":
        "Edit and update search suggestions",
      "page.edit_tracker.button.submit": "Save Topic",
      "page.edit_tracker.button.create_dashboard": "Save and View",
      "page.edit_tracker.button.delete_tracker": "Delete Topic", /// et_delete_btn
      "page.edit_tracker.button.view_dashboard": "View Market brief",
      "page.edit_tracker.status.success.tracker_deleted":
        "Topic successfully deleted",
      "page.edit_tracker.loader.create_dashboard": "Creating Market brief...",
      //#endregion EDIT TRACKER
      //#region SETTINGS
      "page.settings.label.profile": "Profile",
      "page.settings.label.company": "Company",
      "page.settings.label.company_members": "Members",
      "page.settings.label.subscription_plan": "Billing",
      //#region PROFILE
      "page.settings.profile.form.label.first_name": "First Name",
      "page.settings.profile.form.label.last_name": "Last Name",
      "page.settings.profile.form.label.email": "Email",
      "page.settings.profile.form.label.role": "Role",
      "page.settings.profile.form.label.phone_number": "Phone",
      "page.settings.profile.form.label.department": "Department",
      "page.settings.profile.form.validation.first_name_required": "Required",
      "page.settings.profile.form.validation.last_name_required": "Required",
      "page.settings.profile.status.success.user_updated": "Changes saved",
      //#endregion PROFILE
      //#region COMPANY
      "page.settings.company.form.label.name": "Name",
      "page.settings.company.form.label.country": "Country",
      "page.settings.company.form.validation.name_required": "Required",
      "page.settings.company.form.validation.country_required": "Required",
      "page.settings.company.status.success.company_updated": "Changes saved",
      //#endregion COMPANY
      //#region COMPANY MEMBERS
      "page.settings.company_members.label.invite_link": "Copy invite link",
      "page.settings.company_members.table.label.name": "Name",
      "page.settings.company_members.table.label.email": "Email",
      "page.settings.company_members.table.label.role": "Role",
      "page.settings.company_members.table.label.last_active": "Last Activity",
      "page.settings.company_members.table.label.no_members":
        "There are no members in your company",
      "page.settings.company_members.table.button.upgrade_user":
        "Upgrade to admin",
      "page.settings.company_members.table.button.downgrade_user":
        "Downgrade to member",
      "page.settings.company_members.table.button.remove_user": "Remove",
      "page.settings.company_members.status.success.link_copied":
        "Link copied to clipboard",
      //#endregion COMPANY MEMBERS
      //#region SUBSCRIPTION PLAN
      "page.settings.subscription_plan.label.billing_details":
        "Billing Details",
      "page.settings.subscription_plan.label.current_subscription_plan":
        "Current Plan",
      "page.settings.subscription_plan.label.subscription_plan_usage":
        "Plan Usage",
      "page.settings.subscription_plan.label.manage_billing_details":
        "Manage subscription, payment details, billing information and access invoice history.",
      "page.settings.subscription_plan.label.subscription_plan_expire_zero":
        "Ends in <bold>{{count}}</bold> days",
      "page.settings.subscription_plan.label.subscription_plan_expire_one":
        "Ends in <bold>{{count}}</bold> day",
      "page.settings.subscription_plan.label.subscription_plan_expire_other":
        "Ends in <bold>{{count}}</bold> days",
      "page.settings.subscription_plan.label.trackers": "Topics",
      "page.settings.subscription_plan.label.not_available": "Not available",
      "page.settings.subscription_plan.label.trackers_statistic":
        "<div>Created Topics: <bold>{{trackersCount}}</bold></div><div>Topics limit: <bold>{{trackersLimit}}</bold></div>",
      "page.settings.subscription_plan.label.trackers_limit":
        "{{count}}/{{totalCount}}",
      "page.settings.subscription_plan.label.payment_monthly": "Monthly",
      "page.settings.subscription_plan.label.payment_yearly": "Yearly",
      "page.settings.subscription_plan.label.price_zero": "US${{count}}", ///. sp_subscription_plan_sidebar_title_price_zero
      "page.settings.subscription_plan.label.price_other": "From US${{count}}",
      "page.settings.subscription_plan.label.custom_subscription_plan":
        "Enterprise",
      "page.settings.subscription_plan.label.request_custom_subscription_plan":
        "Contact us for pricing<break/>information and more",
      "page.settings.subscription_plan.label.suffix_payment_monthly": "/month",
      "page.settings.subscription_plan.label.subscription_plan_features":
        "<bold>{{name}}</bold> includes",
      "page.settings.subscription_plan.label.subscription_plan_features_trackers":
        "<bold>{{count}}</bold> Topics",
      "page.settings.subscription_plan.label.subscription_plan_features_trackers_starting":
        "From <bold>{{count}}</bold> Topics",
      "page.settings.subscription_plan.label.subscription_plan_features_public_dashboards":
        "<bold>Public Market briefs</bold>",
      "page.settings.subscription_plan.label.subscription_plan_features_monitoring":
        "<bold>Automated monitoring</bold>",
      "page.settings.subscription_plan.label.subscription_plan_features_locations":
        "<bold>All regions</bold>",
      "page.settings.subscription_plan.label.subscription_plan_features_dashboards":
        "<bold>{{count}}</bold> Market briefs",
      "page.settings.subscription_plan.label.subscription_plan_features_events":
        "<bold>{{count}}</bold> events",
      "page.settings.subscription_plan.label.subscription_plan_features_users":
        "<bold>{{count}}</bold> users",
      "page.settings.subscription_plan.label.subscription_plan_features_extra_trackers":
        "Additional Topics: <bold>{{count}}</bold> USD monthly per topic",
      "page.settings.subscription_plan.label.custom_subscription_plan_features_trackers_from":
        "From <bold>{{count}}</bold> Topics",
      "page.settings.subscription_plan.label.custom_subscription_plan_features_public_dashboards":
        "<bold>Public Market briefs</bold>",
      "page.settings.subscription_plan.label.custom_subscription_plan_features_monitoring":
        "<bold>Automated monitoring</bold>",
      "page.settings.subscription_plan.label.custom_subscription_plan_features_locations":
        "<bold>All regions</bold>",
      "page.settings.subscription_plan.label.custom_subscription_plan_features_dashboards":
        "<bold>{{count}}</bold> Market briefs",
      "page.settings.subscription_plan.label.custom_subscription_plan_features_events":
        "<bold>{{count}}</bold> events",
      "page.settings.subscription_plan.label.custom_subscription_plan_features_users":
        "<bold>{{count}}</bold> users",
      "page.settings.subscription_plan.label.custom_subscription_plan_features_support":
        "Onboarding, training & support",
      "page.settings.subscription_plan.label.custom_subscription_plan_features_whitelabel":
        "<bold>Whitelabel</bold> solution",
      "page.settings.subscription_plan.label.unlimited": "Unlimited",
      "page.settings.subscription_plan.button.billing_details":
        "Billing details",
      "page.settings.subscription_plan.button.update_subscription_plan":
        "Upgrade plan",
      "page.settings.subscription_plan.button.contact_us": "Contact us",
      "page.settings.subscription_plan.button.update_trackers": "Update Topics",
      "page.settings.subscription_plan.button.current_subscription_plan":
        "Current",
      "page.settings.subscription_plan.button.select_subscription_plan":
        "Select",
      "page.settings.subscription_plan.button.request_custom_subscription_plan":
        "Contact Us",
      //#endregion SUBSCRIPTION PLAN
      //#endregion SETTINGS
      //#region DASHBOARD
      "page.dashboard.label.dashboard_created_at":
        "Created: <bold>{{createdAt}}</bold>",
      "page.dashboard.label.dashboard_author_name":
        "Owner: <bold>{{ownerFullName}}</bold>",
      "page.dashboard.label.dashboard_visibility":
        "Visibility: <bold>{{privacy}}</bold>",
      "page.dashboard.label.dashboard_last_updated_at":
        "Last updated: <bold>{{updatedAt}}</bold>",
      "page.dashboard.label.dashboard_trackers": "Included Topics",
      "page.dashboard.label.dashboard_events": "Visible events",
      "page.dashboard.label.trackers": "Topics",
      "page.dashboard.label.events": "Events",
      "page.dashboard.label.no_topics": "No Topics found",
      "page.dashboard.label.current_user": "Yours",
      "page.dashboard.label.dashboard_date_range":
        "{{startDate}} - {{endDate}}",
      "page.dashboard.label.dashboard_date_range_type": "{{type}}",
      "page.dashboard.label.options_no_trackers": "No options",
      "page.dashboard.label.options_select_trackers": "Select topics",
      "page.dashboard.label.options_all_trackers_selected": "Topics (all)",
      "page.dashboard.label.options_trackers_selected": "Topics ({{count}})",
      "page.dashboard.form.title": "New date range",
      "page.dashboard.form.create_dashboard_date_range.label.start_date":
        "Start Date",
      "page.dashboard.form.create_dashboard_date_range.label.endDate":
        "End Date",
      "page.dashboard.form.create_dashboard_date_range.submit": "Save",
      "page.dashboard.form.create_dashboard_date_range.validation.date_exists":
        "Already exists",
      "page.dashboard.form.create_dashboard_date_range.validation.invalid_date":
        "Invalid date",
      "page.dashboard.form.status.success.dashboard_date_range_created":
        "Date range created",
      "page.dashboard.status.warning.incomplete_trackers":
        "<span>{{count}} Topic missing search term data:</span><button/>",
      "page.dashboard.button.edit_incomplete_tracker": "Edit Topic",
      "page.dashboard.button.create_tracker": "Create Topic",
      "page.dashboard.button.update_dashboard": "Save",
      "page.dashboard.button.create_dashboard": "Save as new",
      "page.dashboard.button.compare_tracker": "Add Topic",
      "page.dashboard.button.expand_layout": "Expand to full layout",
      "page.dashboard.button.reset_layout": "Reset Market brief layout",
      "page.dashboard.button.duplicate_dashboard": "Save to my account",
      "page.dashboard.button.share_dashboard": "Share",
      "page.dashboard.button.include_widget": "Add widget",
      "page.dashboard.button.edit_tracker": "Edit Topic",
      "page.dashboard.button.rename_dashboard": "Rename Market brief",
      "page.dashboard.button.delete_dashboard": "Delete Market brief",
      "page.dashboard.button.update_dashboard_visibility": "Update visibility",
      "page.dashboard.button.create_event": "New Event",
      "page.dashboard.button.open_edit_events": "Manage Events",
      "page.dashboard.button.create_dashboard_date_range": "New date range",
      "page.dashboard.tooltip.delete_dashboard_date_range": "Delete date range",
      "page.dashboard.tooltip.delete_event_dashboard_date_range":
        "Delete event",
      "page.dashboard.tooltip.create_tracker":
        "Create and add Topics to Market brief",
      "page.dashboard.tooltip.update_dashboard": "Save to current Market brief",
      "page.dashboard.tooltip.create_dashboard": "Save as new Market brief",
      "page.dashboard.tooltip.edit_tracker": "Edit Topic",
      "page.dashboard.tooltip.compare_tracker": "Compare",
      "page.dashboard.tooltip.share_dashboard": "Share Market brief",
      "page.dashboard.tooltip.sync_calculation": "Calculation in progress",
      "page.dashboard.tooltip.dashboard_date_range_event": "Event: {{name}}",
      "page.dashboard.status.success.dashboard_visibility_updated":
        "Market brief visibility updated",
      "page.dashboard.form.placeholder.search_query": "Search by Topic name",
      "page.dashboard.form.author_id.all": "All owners",
      "page.dashboard.form.data_source.all": "All data sources",
      "page.dashboard.form.author_id.current_user": "Yours",
      //#endregion DASHBOARD
      //#endregion PAGE
      //#region COMPONENT
      //#region FORM
      "component.form.label.optional": "(optional)",
      "component.form.button.reset": "Reset",
      "component.form.button.submit": "Save",
      "component.form.button.cancel": "Cancel",
      //#endregion FORM
      //#region TABLE
      "component.table.footer.pagination.index":
        "{{firstItemIndex}}-{{lastItemIndex}} of {{totalItemsCount}}",
      "component.table.footer.pagination.items_per_page": "Rows per page:",
      //#endregion TABLE
      //#region INPUT
      "component.input.label.limit": "{{length}}/{{limit}}",
      //#endregion INPUT
      //#region TEXTAREA
      "component.textarea.label.character_limit":
        "Maximum character count ({{limit}}) exceeded",
      "component.textarea.label.word_limit":
        "Maximum word count ({{limit}}) exceeded",
      //#endregion TEXTAREA
      //#region SELECT
      "component.select.placeholder.search_query": "Type to search",
      "component.select.placeholder.no_data": "No options",
      "component.select.placeholder.select_option": "Select here...",
      //#endregion SELECT
      //#region MULTISELECT
      "component.multiselect.placeholder.search_query": "Type to search",
      "component.multiselect.placeholder.no_data": "No options",
      "component.multiselect.label.selected_all": "All selected",
      //#endregion MULTISELECT
      //#region PASSWORD INPUT
      "component.password_input.requirement.title":
        "Password must meet following requirements:",
      "component.password_input.requirements.label.min_length":
        "At least 8 characters",
      "component.password_input.requirements.label.lowercase_character":
        "At least 1 lowercase character",
      "component.password_input.requirements.label.uppercase_character":
        "At least 1 uppercase character",
      "component.password_input.requirements.label.numbers":
        "At least 1 number or special character",
      //#endregion PASSWORD INPUT
      //#region MENU DROPDOWN
      "component.menu_dropdown.label.option":
        "{{optionLabel}}: {{subOptionLabel}}",
      //#endregion MENU DROPDOWN
      //#region COUNTRIES DROPDOWN
      "component.countries_dropdown.title": "Region selection",
      "component.countries_dropdown.tooltip.one_option.single_option":
        "Only one location available",
      //#endregion COUNTRIES DROPDOWN
      //#region LANGUAGES DROPDOWN
      "component.languages_dropdown.title": "Language selection",
      "component.languages_dropdown.tooltip.single_option":
        "Only one language available",
      //#endregion LANGUAGES DROPDOWN
      //#region KEYWORDS DATA SOURCES DROPDOWN
      "component.keywords_data_sources.title":
        "Search terms data sources selection",
      //#endregion KEYWORDS DATA SOURCES DROPDOWN
      //#region HEADER
      "component.header.button.update_subscription_plan": "Upgrade",
      "component.header.button.contact_us": "Contact us",
      "component.header.tooltip.explore_trend": "Explore",
      "component.header.tooltip.search_dashboard": "Search all Market briefs",
      "component.header.tooltip.create_dashboard": "Create Market brief",
      "component.header.tooltip.generate_dashboard": "Compile Market brief",
      "component.header.tooltip.global_search": "Your Market briefs and Topics",
      "component.header.label.free_subscription_plan":
        "<bold>{{name}}</bold> plan",
      "component.header.label.subscription_plan_expiration_zero":
        "<bold>{{name}}</bold> ends in <bold>{{count}}</bold> days",
      "component.header.label.subscription_plan_expiration_one":
        "<bold>{{name}}</bold> ends in <bold>{{count}}</bold> day",
      "component.header.label.subscription_plan_expiration_other":
        "<bold>{{name}}</bold> ends in <bold>{{count}}</bold> days",
      "component.header.label.tracker_limit_zero": "Max Topics limit reached",
      "component.header.label.tracker_limit_one":
        "<bold>{{count}}</bold> Topic limit remains",
      "component.header.label.tracker_limit_other":
        "<bold>{{count}}</bold> Topics limit remains",
      "component.header.label.tracker_limit_exceeded":
        "Max Topics limit exceeded",
      "component.header.status.info.contact_company_owner":
        "Contact the company owner (<bold>{{email}}</bold>) to update your plan",
      "component.header.status.info.contact_support":
        "Contact customer support to update your plan",
      "component.header.button.profile": "Profile",
      "component.header.button.company": "Company",
      "component.header.button.company_members": "Members",
      "component.header.button.subscription_plan": "Billing",
      "component.header.button.log_out": "Log out",
      //#endregion HEADER
      //#region FOOTER
      "component.footer.label.powered_by":
        "Beta version - Powered by MyTelescope",
      //#endregion FOOTER
      //#region KEYWORDS TABLE
      "component.keywords_table.button.submit": "Update",
      "component.keywords_table.button.cancel": "Cancel",
      "component.keywords_table.button.smart_select": "Smart select",
      "component.keywords_table.button.show_duplicates":
        "Show possible duplicates",
      "component.keywords_table.label.no_keywords": "No search terms available",
      "component.keywords_table.label.duplicate_group":
        "Possible duplicates group {{number}}",
      "component.keywords_table.label.total_keywords":
        "Total related search terms",
      "component.keywords_table.label.total_exact_match_keywords":
        "Total exact match search terms",
      "component.keywords_table.label.keywords_count":
        "Search terms <bold>{{count}}</bold>",
      "component.keywords_table.label.location": "Region",
      "component.keywords_table.label.language": "Language",
      "component.keywords_table.label.search": "Search",
      "component.keywords_table.label.selected_keywords":
        "Selected search terms",
      "component.keywords_table.label.duplicates_count":
        "Possible duplicates <bold>{{count}}</bold>",
      "component.keywords_table.label.search_volume":
        "Search Volume <bold>{{count}}</bold>",
      "component.keywords_table.label.keywords_info": "Search term selection",
      "component.keywords_table.label.keywords_instruction":
        "Select search terms to be included in search index.",
      "component.keywords_table.label.related_keywords": "Related",
      "component.keywords_table.label.exact_match_keywords": "Exact match",
      "component.keywords_table.table.label.keywords": "Search terms",
      "component.keywords_table.table.label.search_volume": "Search volume",
      "component.keywords_table.tooltip.duplicate_group": "Possible duplicate",
      "component.keywords_table.tooltip.search_link":
        "Google Search '{{keyword}}'",
      "component.keywords_table.tooltip.image_search_link":
        "Google Image Search '{{keyword}}'",
      "component.keywords_table.status.success.keywords_updated":
        "Search terms updated",
      "component.keywords_table.loader.download_keywords":
        "Loading search terms...",
      "component.keywords_table.loader.process_keywords":
        "Processing search terms...",
      //#endregion KEYWORDS TABLE
      //#region WIDGET TILE
      "component.widget_tile.label.source": "Based on {{label}}",
      "component.widget_tile.label.keyword_tool_data_source":
        "Search volume data",
      "component.widget_tile.label.no_widget_data": "No data",
      "component.widget_tile.label.no_tracker_data":
        "No data for selected Topic",
      "component.widget_tile.label.comparison_required": "View {{name}}?",
      "component.widget_tile.label.proceed_with_comparison":
        "Add comparison Topic to view {{name}}",
      "component.widget_tile.button.tracker_compare": "Add Topic",
      "component.widget_tile.button.widget_settings": "Settings",
      "component.widget_tile.button.download_excel": "Download Excel",
      "component.widget_tile.button.download_png": "Download PNG",
      "component.widget_tile.button.download_svg": "Download SVG",
      "component.widget_tile.status.info.sync_delay":
        "Due to system overload data collection may take more than usual time",
      "component.widget_tile.status.info.upgrade_subscription_plan":
        "This data model is not available in your current subscription plan. Please upgrade to access this data.",
      "component.widget_tile.status.info.deleted_trackers_collection":
        "Market brief configuration not found",
      "component.widget_tile.loader.calculate_widget_data":
        "Retrieving and analyzing thousands of search terms. Each Topic takes approximately 15-30 seconds...",
      "component.widget_tile.tooltip.widget_settings": "Settings",
      "component.widget_tile.tooltip.widget_resize": "Resize",
      "component.widget_tile.tooltip.forecast_calculation": "Forecasting...",
      //#endregion WIDGET TILE
      //#region SELECTED SEARCH
      "component.selected_search.label.add_search": "Added search",
      "component.selected_search.label.search_link": "Google Search",
      "component.selected_search.label.image_search_link":
        "Google image Search",
      "component.selected_search.label.settings": "Search terms selection",
      "component.selected_search.label.unselect_search": "Delete",
      "component.selected_search.label.edit_search": "Edit",
      "component.selected_search.label.duplicate_search": "Duplicate",
      "component.selected_search.label.update_description":
        "Update description",
      "component.selected_search.loader.create_search": "Creating search...",
      "component.selected_search.loader.process_keywords": "Processing data...",
      "component.selected_search.label.selected_keywords":
        "Selected search terms: <button>{{count}} ({{volume}})</button>",
      "component.selected_search.label.no_keywords":
        "No search terms available",
      "component.selected_search.label.incomplete_keywords":
        "Incomplete or unavailable search term data",
      "component.selected_search.label.review_keywords": "Review",
      "component.selected_search.label.empty_keywords_selection":
        "No selected search terms",
      //#endregion SELECTED SEARCH
      //#region SELECTED TRACKER
      "component.selected_tracker.label.add_tracker": "Added topic",
      "component.selected_tracker.label.search_link": "Google Search",
      "component.selected_tracker.label.edit_tracker": "Edit",
      "component.selected_tracker.label.image_search_link":
        "Google image Search",
      "component.selected_tracker.label.settings": "Advanced settings",
      "component.selected_tracker.label.unselect_tracker": "Delete",
      "component.selected_tracker.label.duplicate_tracker": "Duplicate",
      "component.selected_tracker.tooltip.tracker_description":
        "AI selects relevant search terms based on the topic description",
      "component.selected_tracker.loader.create_search": "Processing data...",
      "component.selected_tracker.status.warning.tracker_removed":
        "Topic <bold>{{name}}</bold> has not been added. Try to add again",
      //#endregion SELECTED TRACKER
      //#region MODAL
      //#region RESET
      "component.modal.reset.title": "Reset changes",
      "component.modal.reset.button.submit": "Reset",
      "component.modal.reset.button.cancel": "Cancel",
      "component.modal.reset.body": "Do you want to reset unsaved changes?",
      //#endregion RESET
      //#region REDIRECT
      "component.modal.redirect.title": "Unsaved changes",
      "component.modal.redirect.button.submit": "Leave",
      "component.modal.redirect.button.cancel": "Cancel",
      "component.modal.redirect.body":
        "Are you sure you want to leave this page?<break/>Changes you made will not be saved",
      //#endregion REDIRECT
      //#region CREATE EVENT
      "component.modal.create_event.title": "New Event",
      "component.modal.create_event.button.submit": "Save",
      "component.modal.create_event.button.cancel": "Cancel",
      "component.modal.create_event.form.label.name": "Name",
      "component.modal.create_event.form.label.description": "Description",
      "component.modal.create_event.form.label.event_type": "Event Type",
      "component.modal.create_event.form.label.date": "Date",
      "component.modal.create_event.form.label.start_date": "Start Date",
      "component.modal.create_event.form.label.end_date": "End Date",
      "component.modal.create_event.form.placeholder.name": "Name",
      "component.modal.create_event.form.placeholder.description":
        "Description",
      "component.modal.create_event.form.placeholder.event_type": "Event Type",
      "component.modal.create_event.form.placeholder.date": "Date",
      "component.modal.create_event.form.placeholder.start_date": "Start Date",
      "component.modal.create_event.form.placeholder.endDate": "End Date",
      "component.modal.create_event.form.validation.name_required": "Required",
      "component.modal.create_event.form.validation.name_exists":
        "Already exists",
      "component.modal.create_event.form.validation.date_exists":
        "Already exists",
      "component.modal.create_event.form.validation.invalid_date":
        "Invalid date",
      "component.modal.create_event.form.status.success.event_created":
        "Event created",
      //#endregion CREATE EVENT
      //#region EDIT EVENT
      "component.modal.edit_event.title": "Edit Event: {{name}}",
      "component.modal.edit_event.button.submit": "Update",
      "component.modal.edit_event.button.cancel": "Cancel",
      "component.modal.edit_event.form.label.name": "Name",
      "component.modal.edit_event.form.label.description": "Description",
      "component.modal.edit_event.form.label.event_type": "Event Type",
      "component.modal.edit_event.form.label.date": "Date",
      "component.modal.edit_event.form.label.start_date": "Start Date",
      "component.modal.edit_event.form.label.end_date": "End Date",
      "component.modal.edit_event.form.placeholder.name": "Name",
      "component.modal.edit_event.form.placeholder.description": "Description",
      "component.modal.edit_event.form.placeholder.event_type": "Event Type",
      "component.modal.edit_event.form.placeholder.date": "Date",
      "component.modal.edit_event.form.placeholder.start_date": "Start Date",
      "component.modal.edit_event.form.placeholder.endDate": "End Date",
      "component.modal.edit_event.form.validation.name_required": "Required",
      "component.modal.edit_event.form.validation.name_exists":
        "Already exists",
      "component.modal.edit_event.form.validation.date_exists":
        "Already exists",
      "component.modal.edit_event.form.validation.incorrect_date":
        "Start date should be after end date",
      "component.modal.edit_event.form.status.success.event_created":
        "Event created",
      //#endregion EDIT EVENT
      //#region SHARE DASHBOARD
      "component.modal.share_dashboard.title": "Share analysis",
      "component.modal.share_dashboard.button.submit": "Generate & Copy link",
      "component.modal.share_dashboard.label.default_date_range":
        "This determines the initial date range setting viewers will see when enter the market brief",
      "component.modal.share_dashboard.label.read_only": "Read-only",
      "component.modal.share_dashboard.label.update_allowed":
        "Keep analysis data up-to-date",
      "component.modal.share_dashboard.status.success.link_copied":
        "Link copied to clipboard",
      "component.modal.share_dashboard.label.access_type": "Access Type",
      "component.modal.share_dashboard.label.date_range": "Date Range",
      "component.modal.share_dashboard.label.selected_period":
        "Selected period",
      "component.modal.share_dashboard.label.data_update": "Data Updating",
      //#endregion SHARE DASHBOARD
      //#region EDIT TRACKER CONFIGURATION
      "component.modal.edit_tracker_configuration.title": "Edit Topic",
      "component.modal.edit_tracker_configuration.button.submit": "Save",
      "component.modal.edit_tracker_configuration.button.cancel": "Cancel",
      "component.modal.edit_tracker_configuration.form.label.name": "Name",
      "component.modal.edit_tracker_configuration.form.label.description":
        "Description",
      "component.modal.edit_tracker_configuration.form.label.category":
        "Category",
      "component.modal.edit_tracker_configuration.form.placeholder.name":
        "Name",
      "component.modal.edit_tracker_configuration.form.placeholder.description":
        "Description",
      "component.modal.edit_tracker_configuration.form.placeholder.category":
        "Category",
      "component.modal.edit_tracker_configuration.form.validation.tracker_exists":
        "Tracker already added",
      "component.modal.edit_tracker_configuration.form.validation.name_required":
        "Required",
      "component.modal.edit_tracker_configuration.form.validation.description_required":
        "Required",
      "component.modal.edit_tracker_configuration.form.validation.category_required":
        "Required",
      //#endregion EDIT TRACKER CONFIGURATION
      //#region EDIT TRACKER
      "component.modal.edit_tracker.title": "Edit Topic",
      "component.modal.edit_tracker.button.submit": "Save",
      "component.modal.edit_tracker.button.cancel": "Cancel",
      "component.modal.edit_tracker.form.label.name": "Name",
      "component.modal.edit_tracker.form.label.description": "Description",
      "component.modal.edit_tracker.form.label.category": "Category",
      "component.modal.edit_tracker.form.label.keywords_data_source":
        "Search terms Data Source",
      "component.modal.edit_tracker.form.label.location": "Location",
      "component.modal.edit_tracker.form.label.language": "Language",
      "component.modal.edit_tracker.form.placeholder.name": "Name",
      "component.modal.edit_tracker.form.placeholder.description":
        "Description",
      "component.modal.edit_tracker.form.placeholder.category": "Category",
      "component.modal.edit_tracker.form.validation.tracker_exists":
        "Tracker already added",
      "component.modal.edit_tracker.form.validation.name_required": "Required",
      "component.modal.edit_tracker.form.validation.description_required":
        "Required",
      "component.modal.edit_tracker.form.validation.category_required":
        "Required",
      "component.modal.edit_tracker.status.warning.tracker_update":
        "The Topic will be re-created when changes are saved",
      //#endregion EDIT TRACKER
      //#region DUPLICATE TRACKER
      "component.modal.duplicate_tracker.title": "Copy Topic",
      "component.modal.duplicate_tracker.button.submit": "Save",
      "component.modal.duplicate_tracker.button.cancel": "Cancel",
      "component.modal.duplicate_tracker.form.label.name": "Name",
      "component.modal.duplicate_tracker.form.label.description": "Description",
      "component.modal.duplicate_tracker.form.label.category": "Category",
      "component.modal.duplicate_tracker.form.label.location": "Location",
      "component.modal.duplicate_tracker.form.label.language": "Language",
      "component.modal.duplicate_tracker.form.placeholder.name": "Name",
      "component.modal.duplicate_tracker.form.placeholder.description":
        "Description",
      "component.modal.duplicate_tracker.form.placeholder.category": "Category",
      "component.modal.duplicate_tracker.form.label.keywords_data_source":
        "Search terms Data Source",
      "component.modal.duplicate_tracker.form.validation.tracker_exists":
        "Tracker already added",
      "component.modal.duplicate_tracker.form.validation.name_required":
        "Required",
      "component.modal.duplicate_tracker.form.validation.description_required":
        "Required",
      "component.modal.duplicate_tracker.form.validation.category_required":
        "Required",
      "component.modal.duplicate_tracker.status.warning.tracker_update":
        "The Topic will be re-created when changes are saved",
      //#endregion DUPLICATE TRACKER
      //#region CREATE DASHBOARD
      "component.modal.create_dashboard.title": "Compile Market brief",
      "component.modal.create_dashboard.button.submit": "Save",
      "component.modal.create_dashboard.button.cancel": "Cancel",
      "component.modal.create_dashboard.label.current_user": "Yours",
      "component.modal.create_dashboard.form.label.name": "Name",
      "component.modal.create_dashboard.form.label.trackers": "Topics",
      "component.modal.create_dashboard.form.label.visibility": "Visibility",
      "component.modal.create_dashboard.form.placeholder.name": "Name",
      "component.modal.create_dashboard.form.placeholder.trackers": "Topics",
      "component.modal.create_dashboard.form.placeholder.visibility":
        "Visibility",
      "component.modal.create_dashboard.form.validation.name_required":
        "Required",
      "component.modal.create_dashboard.form.validation.name_exists":
        "Already exists",
      "component.modal.create_dashboard.form.validation.trackers_required":
        "Required",
      "component.modal.create_dashboard.status.warning.incomplete_tracker":
        "Missing search term data",
      //#endregion CREATE DASHBOARD
      //#region DUPLICATE DASHBOARD
      "component.modal.duplicate_dashboard.title": "Save Market brief",
      "component.modal.duplicate_dashboard.button.submit": "Save",
      "component.modal.duplicate_dashboard.button.cancel": "Cancel",
      "component.modal.duplicate_dashboard.form.label.name": "Name",
      "component.modal.duplicate_dashboard.form.placeholder.name": "Name",
      "component.modal.duplicate_dashboard.form.validation.name_required":
        "Required",
      "component.modal.duplicate_dashboard.status.warning.data_update_one":
        "Saving this Market brief will add <bold>{{count}}</bold> Topic to your account. The Market brief data will be updated from this point forward, according to the update frequency provided in your subscription plan.",
      "component.modal.duplicate_dashboard.status.warning.data_update_other":
        "Saving this Market brief will add <bold>{{count}}</bold> Topic to your account. The Market brief data will be updated from this point forward, according to the update frequency provided in your subscription plan.",
      //#endregion DUPLICATE DASHBOARD
      //#region UPGRADE SUBSCRIPTION PLAN
      "component.modal.upgrade_subscription_plan.title": "Save Market brief",
      "component.modal.upgrade_subscription_plan.button.submit": "Upgrade",
      "component.modal.upgrade_subscription_plan.button.cancel": "Cancel",
      //#endregion UPGRADE SUBSCRIPTION PLAN
      //#region REQUEST SUBSCRIPTION PLAN
      "component.modal.request_subscription_plan.title": "Enterprise Request",
      "component.modal.request_subscription_plan.button.submit": "Send",
      "component.modal.request_subscription_plan.button.cancel": "Cancel",
      "component.modal.request_subscription_plan.body":
        "Do you want to send a request?",
      "component.modal.request_subscription_plan.status.success.request_sent":
        "Request sent",
      //#endregion REQUEST SUBSCRIPTION PLAN
      //#region DUPLICATE SEARCH
      "component.modal.duplicate_search.title": "Copy {{name}}",
      "component.modal.duplicate_search.button.submit": "Save",
      "component.modal.duplicate_search.button.cancel": "Cancel",
      "component.modal.duplicate_search.form.label.name": "Search term",
      "component.modal.duplicate_search.form.label.description": "Description",
      "component.modal.duplicate_search.form.label.location": "Region",
      "component.modal.duplicate_search.form.label.language": "Language",
      "component.modal.duplicate_search.form.placeholder.name": "Search term",
      "component.modal.duplicate_search.form.placeholder.description":
        "Description",
      "component.modal.duplicate_search.form.validation.name_required":
        "Required",
      //#endregion DUPLICATE SEARCH
      //#region EDIT SEARCH
      "component.modal.edit_search.title": "Edit {{name}}",
      "component.modal.edit_search.button.submit": "Save",
      "component.modal.edit_search.button.cancel": "Cancel",
      "component.modal.edit_search.form.label.name": "Search term",
      "component.modal.edit_search.form.label.description": "Description",
      "component.modal.edit_search.form.label.location": "Region",
      "component.modal.edit_search.form.label.language": "Language",
      "component.modal.edit_search.form.label.keywords_data_source":
        "Search terms Data Source",
      "component.modal.edit_search.form.placeholder.name": "Search term",
      "component.modal.edit_search.form.placeholder.description": "Description",
      "component.modal.edit_search.form.validation.name_required": "Required",
      "component.modal.edit_search.status.warning.search_update":
        "The search will be re-created when the changes are saved",
      //#endregion EDIT SEARCH
      //#region EDIT INSIGHTS
      "component.modal.edit_insights.title": "Edit Summary",
      "component.modal.edit_insights.button.submit": "Save",
      "component.modal.edit_insights.button.cancel": "Cancel",
      "component.modal.edit_insights.checkbox.confirmation":
        "I understand that this text will be reset if changes are made to the Market brief or included topics. Also when data is updated.",
      "component.modal.edit_insights.status.success.data_updated":
        "Widget text updated successfully",
      //#endregion EDIT INSIGHTS
      //#region RESET INSIGHTS
      "component.modal.reset_insights.title": "Reset Summary",
      "component.modal.reset_insights.button.submit": "Reset",
      "component.modal.reset_insights.button.cancel": "Cancel",
      "component.modal.reset_insights.body":
        "All your changes will be discarded, and the original AI-generated text will be restored.<break/>Are you sure you want to proceed with the reset?",
      "component.modal.reset_insights.status.success.data_updated":
        "Widget text reset successfully",
      //#endregion CONFIRM RESET INSIGHTS
      //#region CONFIRM EDIT TRACKER CONFIGURATION
      "component.modal.confirm_edit_tracker_configuration.title":
        "Save changes",
      "component.modal.confirm_edit_tracker_configuration.button.submit":
        "Save",
      "component.modal.confirm_edit_tracker_configuration.button.cancel":
        "Cancel",
      "component.modal.confirm_edit_tracker_configuration.body":
        "Do you want to save and recalculate Market brief's widgets?",
      //#endregion CONFIRM EDIT TRACKER CONFIGURATION
      //#region DELETE TRACKER
      "component.modal.delete_tracker.title": "Delete Topic",
      "component.modal.delete_tracker.button.submit": "Delete",
      "component.modal.delete_tracker.button.cancel": "Cancel",
      "component.modal.delete_tracker.body":
        "Are you sure you want to delete the Topic?",
      "component.modal.delete_tracker.status.warning.dashboard_update":
        "It will be permanently deleted from the following Market briefs:",
      //#endregion DELETE TRACKER
      //#region DELETE DASHBOARD
      "component.modal.delete_dashboard.title": "Delete Market brief",
      "component.modal.delete_dashboard.button.submit": "Delete",
      "component.modal.delete_dashboard.button.cancel": "Cancel",
      "component.modal.delete_dashboard.label.delete_dashboard":
        "Do you want to delete this Market brief? It will be permanently deleted.",
      "component.modal.delete_dashboard.label.delete_trackers":
        "Delete included Topics?",
      "component.modal.delete_dashboard.status.success.dashboard_deleted":
        "Market brief deleted",
      //#endregion DELETE DASHBOARD
      //#region CREATE TRACKERS COLLECTION
      "component.modal.create_trackers_collection.title":
        "New Market brief's name",
      "component.modal.create_trackers_collection.button.submit": "Save",
      "component.modal.create_trackers_collection.button.cancel": "Cancel",
      "component.modal.create_trackers_collection.form.validation.name_required":
        "Required",
      "component.modal.create_trackers_collection.form.validation.name_exists":
        "You already have a Market brief with such name",
      //#endregion CREATE TRACKERS COLLECTION
      //#region RENAME DASHBOARD
      "component.modal.rename_dashboard.title": "Market brief's name",
      "component.modal.rename_dashboard.button.submit": "Save",
      "component.modal.rename_dashboard.button.cancel": "Cancel",
      "component.modal.rename_dashboard.form.placeholder.name": "Name",
      "component.modal.rename_dashboard.form.validation.name_required":
        "Required",
      "component.modal.rename_dashboard.form.validation.name_exists":
        "You already have a Market brief with such name",
      //#endregion RENAME DASHBOARD
      //#region DELETE COMPANY MEMBER
      "component.modal.delete_company_member.title": "Remove {{name}}",
      "component.modal.delete_company_member.body":
        "Do you want to remove the member from company?",
      "component.modal.delete_company_member.button.submit": "Submit",
      "component.modal.delete_company_member.button.cancel": "Cancel",
      //#endregion DELETE COMPANY MEMBER
      //#region CREATE DASHBOARD DATE RANGE
      "component.modal.create_dashboard_date_range.title": "New date range",
      "component.modal.create_dashboard_date_range.button.submit": "Save",
      "component.modal.create_dashboard_date_range.button.view": "View",
      "component.modal.create_dashboard_date_range.button.cancel": "Cancel",
      "component.modal.create_dashboard_date_range.button.create_event":
        "New Event",
      "component.modal.create_dashboard_date_range.form.label.start_date":
        "Start Date",
      "component.modal.create_dashboard_date_range.form.label.end_date":
        "End Date",
      "component.modal.create_dashboard_date_range.form.label.new_event":
        "Want to create date range as event?",
      "component.modal.create_dashboard_date_range.form.validation.incorrect_date":
        "Incorrect date",
      "component.modal.create_dashboard_date_range.form.status.success.dashboard_date_range_created":
        "Date range created",
      //#endregion CREATE DASHBOARD DATE RANGE
      //#region DELETE DASHBOARD DATE RANGE
      "component.modal.delete_dashboard_date_range.title": "Delete date range",
      "component.modal.delete_dashboard_date_range.title.alt": "Delete event",
      "component.modal.delete_dashboard_date_range.body":
        "Do you want to delete the date range?",
      "component.modal.delete_dashboard_date_range.body.alt":
        "Do you want to delete event?",
      "component.modal.delete_dashboard_date_range.button.submit": "Delete",
      "component.modal.delete_dashboard_date_range.button.cancel": "Cancel",
      "component.modal.delete_dashboard_date_range.status.success.dashboard_date_range_deleted":
        "Date range removed",
      //#endregion DELETE DASHBOARD DATE RANGE
      //#region ADD TRACKER
      "component.modal.add_tracker.title": "Add topic",
      "component.modal.add_tracker.button.submit": "Add",
      "component.modal.add_tracker.button.cancel": "Cancel",
      "component.modal.add_tracker.form.placeholder.name":
        "Enter your own topic",
      "component.modal.add_tracker.form.validation.name_required": "Required",
      //#emdregion ADD TRACKER
      //#region ADD SEARCH
      "component.modal.add_search.title": "Add search",
      "component.modal.add_search.button.submit": "Add",
      "component.modal.add_search.button.cancel": "Cancel",
      "component.modal.add_search.form.placeholder.name":
        "Enter your own search",
      "component.modal.add_search.form.validation.name_required": "Required",
      //#emdregion ADD SEARCH
      //#endregion MODAL
      //#region SIDEBAR
      //#region GLOBAL SEARCH
      "component.sidebar.global_search.label.dashboards": "Market briefs",
      "component.sidebar.global_search.label.trackers": "Topics",
      "component.sidebar.global_search.label.current_user": "Yours",
      "component.sidebar.global_search.form.placeholder.search_query":
        "Search by Market brief or Topic name",
      "component.sidebar.global_search.form.author_id.all": "All owners",
      "component.sidebar.global_search.form.author_id.current_user": "Yours",
      "component.sidebar.global_search.form.data_source.all":
        "All data sources",
      "component.sidebar.global_search.tooltip.delete_dashboard":
        "Delete Market brief",
      "component.sidebar.global_search.tooltip.delete_tracker": "Delete Topic",
      "component.sidebar.global_search.label.no_dashboard":
        "No Market briefs found",
      "component.sidebar.global_search.status.warning.incomplete_tracker":
        "Missing search term data",
      //#endregion GLOBAL SEARCH
      //#region DASHBOARD WIDGETS
      "component.sidebar.dashboard_widgets.label.unavailable": "Locked",
      "component.sidebar.dashboard_widgets.label.available": "Available",
      "component.sidebar.dashboard_widgets.label.competitor_required":
        "Available with comparisons",
      "component.sidebar.dashboard_widgets.label.upgrade_required":
        "Available with upgrade",
      "component.sidebar.dashboard_widgets.label.no_widgets":
        "You have added all available widgets for this Market brief",
      //#endregion DASHBOARD PAGE --- Dashboard sidebar
      //#region DASHBOARD EVENTS
      "component.sidebar.dashboard_events.title": "Events",
      "component.sidebar.dashboard_events.label.no_events": "No events founds",
      "component.sidebar.dashboard_events.button.submit": "Save",
      "component.sidebar.dashboard_events.button.remove": "Delete",
      "component.sidebar.dashboard_events.button.edit": "Edit",
      "component.sidebar.dashboard_events.button.activate": "Include",
      "component.sidebar.dashboard_events.button.deactivate": "Exclude",
      "component.sidebar.dashboard_events.tooltip.create_event": "New Event",
      "component.sidebar.dashboard_events.status.success.events_updated":
        "Market brief events updated",
      "component.sidebar.dashboard_events.form.placeholder.search_query":
        "Search by event name",
      //#endregion DASHBOARD EVENTS
      //#endregion SIDEBAR
      //#region NOTIFICATION
      //#region UPDATED VERSION
      "component.notification.updated_version.body":
        "New application version available",
      "component.notification.updated_version.button.refresh": "Refresh",
      //#endregion UPDATED VERSION
      //#region UPDATED SUBSCRIPTION PLAN
      "component.notification.updated_subscription_plan.body":
        "Subscription plan has been updated",
      "component.notification.updated_subscription_plan.button.refresh":
        "Refresh",
      //#endregion NEW SUBSCRIPTION PLAN NOTIFICATION
      //#endregion NOTIFICATION
      //#endregion COMPONENT
      //#region AUTHENTICATION
      //#region AUTHENTICATION HEADER
      "authentication.header.button.redirect_to_login": "Log in",
      "authentication.header.button.redirect_to_signup": "Sign up",
      "authentication.header.button.signup": "Create new account?",
      "authentication.header.button.login": "Already have an account?",
      //#endregion AUTHENTICATION HEADER
      //#region LOGIN
      "authentication.login.title": "Welcome",
      "authentication.login.label.forgotten_password": "Forgot your password?",
      "authentication.login.button.reset_password": "Reset password",
      "authentication.login.form.button.submit": "Log in",
      "authentication.login.form.label.email": "Email",
      "authentication.login.form.label.password": "Password",
      "authentication.login.form.placeholder.email": "Email",
      "authentication.login.form.placeholder.password": "Password",
      "authentication.login.form.validation.invalid_email": "Invalid email",
      "authentication.login.form.validation.email_required": "Required",
      "authentication.login.form.validation.password_required": "Required",
      //#endregion LOGIN
      //#region SIGNUP
      "authentication.signup.title": "Welcome",
      "authentication.signup.form.label.first_name": "First Name",
      "authentication.signup.form.label.last_name": "Last Name",
      "authentication.signup.form.label.email": "Work Email",
      "authentication.signup.form.label.password": "Password",
      "authentication.signup.form.label.company_name": "Company Name",
      "authentication.signup.form.label.location": "Country",
      "authentication.signup.form.placeholder.first_name": "First Name",
      "authentication.signup.form.placeholder.last_name": "Last Name",
      "authentication.signup.form.placeholder.email": "Work Email",
      "authentication.signup.form.placeholder.password": "Password",
      "authentication.signup.form.placeholder.company_name": "Company Name",
      "authentication.signup.form.placeholder.location": "Country",
      "authentication.signup.form.button.submit": "Sign up",
      "authentication.signup.form.validation.required": "Required",
      "authentication.signup.form.validation.invalid_email": "Invalid email",
      "authentication.signup.form.validation.email_exists":
        "Email is already in use",
      "authentication.signup.form.validation.invalid_password":
        "Password does not meet the requirements",
      "authentication.signup.label.terms_and_condition":
        "By signing up, you agree to <termsLink>MyTelescope’s Terms and conditions of use</termsLink> and <privacyLink>Privacy policy.</privacyLink>>",
      "authentication.signup.status.warning.not_supported_location":
        "*We currently don’t collect data from {{selectedCountryName}}. You can still create an account and research data from other regions.",
      //#endregion SIGNUP
      //#region PASSWORD RESET
      "authentication.password_reset.title": "Password reset",
      "authentication.password_reset.label.email_sent":
        "Confirmation email sent. Please, check your mail",
      "authentication.password_reset.button.redirect_to_login":
        "Back to log in",
      "authentication.password_reset.form.button.submit": "Send reset link",
      "authentication.password_reset.form.button.resend_email":
        "Resend confirmation email",
      "authentication.password_reset.form.placeholder.email": "Email",
      "authentication.password_reset.form.validation.invalid_email":
        "Invalid email",
      "authentication.password_reset.form.validation.email_required":
        "Required",
      //#endregion PASSWORD RESET
      //#region EMAIL CONFIRMATION
      "authentication.email_confirmation.title": "User verification",
      "authentication.email_confirmation.subtitle":
        "Confirmation email sent. Please, check your mail",
      "authentication.email_confirmation.button.resend_email":
        "Resend confirmation email",
      "authentication.email_confirmation.button.redirect_to_login":
        "Back to log in",
      "authentication.email_confirmation.status.success.email_sent":
        "Email was sent",
      "authentication.email_confirmation.status.success.email_verified":
        "Email successfully verified",
      "authentication.email_confirmation.status.error":
        "Oops, something bad has happened with the link. Please try again with the new link which we have sent to you",
      "authentication.email_confirmation.status.error.broken_link":
        "Oops, something bad has happened with the link",
      "authentication.email_confirmation.status.error.token_expired":
        "Oops, link has expired. Please try again with the new link which we have sent to you",
      "authentication.email_confirmation.status.error.user_not_found":
        "Can't find user with such id or user was deleted. Please contact our support or create a new account",
      //#endregion EMAIL CONFIRMATION
      //#endregion AUTHENTICATION
      //#region WIDGET
      //#region CONFIGURATION
      //#region SETTINGS
      "widget.configuration.settings.title": "Widget Settings",
      "widget.configuration.settings.label.custom_name": "Widget title",
      "widget.configuration.settings.form.placeholder.custom_name":
        "Create a name",
      "widget.configuration.settings.button.update_settings": "Update",
      "widget.configuration.settings.status.success.settings_updated":
        "Widget settings updated",
      //#endregion SETTINGS
      //#endregion CONFIGURATION
      //#region ARTICLES
      "widget.articles.name": "Research and statistics",
      "widget.articles.label.total_count": "<bold>{{count}}</bold> articles",
      "widget.articles.form.placeholder.search_query": "Search articles",
      "widget.articles.data.placeholder.no_articles": "No articles to display",
      "widget.articles.data.placeholder.no_filtered_articles":
        "No articles to display. Try changing the filters",
      //#endregion ARTICLES
      //#region TOP KEYWORDS
      "widget.top_keywords.name": "Top search terms",
      "widget.top_keywords.table.label.keyword": "Search term",
      "widget.top_keywords.table.label.search_volume": "Search volume",
      "widget.top_keywords.table.label.tracker_name": "Topic",
      "widget.top_keywords.table.tooltip.search_volume":
        "Search volume: {{value}}",
      //#endregion TOP KEYWORDS
      //#region TRENDING KEYWORDS
      "widget.trending_keywords.name": "Rising search terms",
      "widget.trending_keywords.table.label.keyword": "Search term",
      "widget.trending_keywords.table.label.search_volume": "Search volume",
      "widget.trending_keywords.table.label.tracker_name": "Topic",
      "widget.trending_keywords.table.label.search_trend": "Volume change",
      "widget.trending_keywords.table.tooltip.search_volume":
        "Search volume: {{value}}",
      "widget.trending_keywords.table.tooltip.search_trend":
        "Volume change: {{value}}",
      //#endregion TRENDING KEYWORDS
      //#region VOLUME SHARE
      "widget.volume_share.name": "MyTelescope Index",
      "widget.volume_share.table.label.name": "Topic",
      "widget.volume_share.table.label.trend_change": "Trend",
      "widget.volume_share.table.label.volume": "Search volume",
      "widget.volume_share.table.label.total":
        "Total Search volume: {{value}} (<additionalValue />)",
      //#endregion VOLUME SHARE
      //#region SEARCH SHARE
      "widget.search_share.name": "MyTelescope Share of Search",
      "widget.search_share.table.label.name": "Topic",
      "widget.search_share.table.label.trend_change": "Trend",
      "widget.search_share.table.label.total_search": "Search share",
      "widget.search_share.table.label.total":
        "Total Search share: {{value}} (<additionalValue />)",
      //#endregion SEARCH SHARE
      //#region INSIGHTS
      "widget.insights.name": "Summary",
      "widget.insights.header.button.copy_text": "Copy text",
      "widget.insights.header.button.edit_text": "Edit text",
      "widget.insights.header.button.reset_text": "Reset text",
      "widget.insights.footer.label.ai_name": "AI",
      "widget.insights.footer.label.current_user_name": "Me",
      "widget.insights.footer.label.updated_at":
        "Last updated by <bold>{{name}}</bold> {{date}}",
      "widget.insights.footer.tooltip.updated_at": "Last update {{date}}",
      "widget.insights.status.success.text_copied": "Text copied",
      //#endregion INSIGHTS
      //#endregion WIDGET
      //#region CHART
      //#region LINE
      "chart.line.name": "Line Chart",
      "chart.line.tooltip.tracker_value":
        "{{value}}{{unit}} {{additionalValue}}",
      "chart.line.status.warning.minimum_trackers":
        "At least one Topic should be selected",
      "chart.line.data.scale.linear": "Linear scale",
      "chart.line.data.scale.logarithmic": "Logarithmic scale",
      "chart.line.data.divider.actual": "ACTUAL",
      "chart.line.data.divider.forecast": "FORECAST",
      "chart.line.data.trend_line": "Trend line",
      "chart.line.event.label": "{{name}} ({{startDate}} - {{endDate}})",
      //#endregion LINE
      //#region PIE
      "chart.pie.name": "Pie Chart",
      "chart.pie.data.label":
        "<icon/><label>{{label}}</label><value>{{value}}</value>",
      "chart.pie.tooltip.label": "{{startDate}} - {{endDate}}",
      "chart.pie.tooltip.value": "{{value}}",
      "chart.pie.data.label.total": "Total",
      //#endregion PIE
      //#region ARTICLES
      "chart.articles.name": "Articles",
      "chart.articles.sort.date": "Latest articles",
      "chart.articles.sort.title": "A-Z",
      "chart.articles.sort.score": "Relevance",
      "chart.articles.label.view_more": "View more",
      "chart.articles.label.link": "Read article",
      "chart.articles.filters.title": "Article filters",
      "chart.articles.filters.button.reset": "Reset filters",
      "chart.articles.filters.button.updated": "Update filters",
      "chart.articles.filters.status.warning.limit_reached": "Limit reached",
      "chart.articles.filters.status.success.filters_updated":
        "Filters updated",
      "chart.articles.filters.label.custom_filter": "Create your own filter",
      "chart.articles.filters.label.included_filters": "Included filters",
      "chart.articles.filters.label.suggested_filters": "Suggested filters",
      "chart.articles.filters.form.placeholder.filter": "Type filter",
      //#endregion ARTICLES
      //#region SENTIMENT
      "chart.sentiment.name": "Sentiment",
      "chart.sentiment.name.positive": "Positive",
      "chart.sentiment.name.neutral": "Neutral",
      "chart.sentiment.name.negative": "Negative",
      //#endregion SENTIMENT
      //#region TABLE
      "chart.table.name": "Table",
      //#endregion TABLE
      //#region LIST
      "chart.list.name": "List",
      //#endregion LIST
      //#region TAG CLOUD
      "chart.tag_cloud.name": "Tag Cloud",
      //#endregion TAG CLOUD
      //#region SPEEDOMETER
      "chart.speedometer.name": "Speedometer",
      //#endregion SPEEDOMETER
      //#region TEXT
      "chart.text.name": "Text",
      //#endregion TEXT
      //#endregion CHART
      //#region TRACKER
      "tracker.status.warning.tracker_limit": "Max Topics limit reached",
      "tracker.status.warning.tracker_unavailable":
        "Not available with current plan",
      "tracker.status.warning.button.upgrade": "Upgrade",
      "tracker.status.warning.button.redirect_to_billing": "Billing",
      "tracker.status.warning.tracker_search_limit_zero":
        "Searches per Topic limit exceeded for this account",
      "tracker.status.warning.tracker_exists": "Topic already exists",
      "tracker.status.warning.tracker_search_limit_one":
        "Limit of <bold>{{count}}</bold> search exceeded",
      "tracker.status.warning.tracker_search_limit_other":
        "Limit of <bold>{{count}}</bold> searches exceeded",
      "tracker.label.location_mixed": "Multiple",
      "tracker.label.category_mixed": "Multiple",
      "tracker.label.default_description": "No description",
      "tracker.label.sort_name": "A to Z",
      "tracker.label.sort_created_at": "Newest",
      "tracker.label.last_viewed": "Last viewed",
      "tracker.category.default_name": "Exact match",
      "tracker.category.default_description": "Exact match of {{query}} with",
      //#endregion TRACKER
      //#region SEARCH
      "search.status.warning.search_exists": "Search already exists",
      "search.label.keywords_data_source_google": "Google",
      "search.label.keywords_data_source_tiktok": "TikTok",
      "search.label.keywords_data_source_amazon": "Amazon",
      "search.label.keywords_data_source_youtube": "YouTube",
      "search.label.keywords_data_source_instagram": "Instagram",
      "search.label.keywords_data_source_bing": "Bing",
      "search.label.keywords_data_source_ebay": "eBay",
      "search.label.keywords_data_source_pinterest": "Pinterest",
      "search.label.keywords_data_source_twitter": "Twitter",
      "search.label.keywords_data_source_etsy": "Etsy",
      "search.label.keywords_data_source_app_store": "App Store",
      "search.label.keywords_data_source_play_store": "Play Store",
      //#endregion SEARCH
      //#region DASHBOARD
      "dashboard.status.warning.dashboard_limit_zero":
        "Market brief limit exceeded for this account",
      "dashboard.status.warning.dashboard_limit_one":
        "Limit of <bold>{{count}}</bold> Market brief exceeded",
      "dashboard.status.warning.dashboard_limit_other":
        "Limit of <bold>{{count}}</bold> Market briefs exceeded",
      "dashboard.label.sort_name": "A to Z",
      "dashboard.label.sort_created_at": "Newest",
      "dashboard.label.last_viewed": "Last viewed",
      "dashboard.label.visibility_private": "Private",
      "dashboard.label.visibility_public": "Public",
      "dashboard.label.visibility_company": "Company",
      //#endregion DASHBOARD
      //#region EVENT
      "event.label.date_range_type_single": "Single",
      "event.label.date_range_type_period": "Period",
      "event.status.warning.event_limit_zero":
        "Events limit exceeded for this account",
      "event.status.warning.event_limit_one":
        "Limit of <bold>{{count}}</bold> event exceeded",
      "event.status.warning.event_limit_other":
        "Limit of <bold>{{count}}</bold> events exceeded",
      //#endregion EVENT
      //#region COMPANY
      "company.team.status.warning.team_limit_zero":
        "Team members limit exceeded for this account",
      "company.team.status.warning.team_limit_one":
        "Limit of <bold>{{count}}</bold> team member exceeded",
      "company.team.status.warning.team_limit_other":
        "Limit of <bold>{{count}}</bold> team members exceeded",
      "company.team.status.warning.admin_limit_zero":
        "Team admins limit exceeded for this account",
      "company.team.status.warning.admin_limit_one":
        "Limit of <bold>{{count}}</bold> team admin exceeded",
      "company.team.status.warning.admin_limit_other":
        "Limit of <bold>{{count}}</bold> team admins exceeded",
      //#endregion COMPANY
      //#region TRACKERS COLLECTION
      "trackers_collection.status.warning.trackers_collection_limit_zero":
        "Topics per Market brief limit exceeded for this account",
      "trackers_collection.status.warning.trackers_collection_limit_one":
        "Limit of <bold>{{count}}</bold> Topic per Market brief exceeded",
      "trackers_collection.status.warning.trackers_collection_limit_other":
        "Limit of <bold>{{count}}</bold> Topics per Market brief exceeded",
      "trackers_collection.status.warning.tracker_limit_one":
        "Limit of {{count}} topic per Market brief reached",
      "trackers_collection.status.warning.tracker_limit_zero":
        "Limit of {{count}} topics per Market brief reached",
      "trackers_collection.status.warning.tracker_limit_other":
        "Limit of {{count}} topics per Market brief reached",
      //#endregion TRACKERS COLLECTION
      //#region DASHBOARD DATE RANGE
      "dashboard_date_range.label.all": "All time",
      "dashboard_date_range.label.last_twelve_month": "Past 12 months",
      "dashboard_date_range.label.last_six_month": "Past 6 months",
      "dashboard_date_range.label.last_three_month": "Past 3 months",
      "dashboard_date_range.label.last_one_month": "Past 1 month",
      //#endregion DASHBOARD DATE RANGE
      //#region USER
      "user.name.current_user": "You",
      //#endregion USER
    },
  },
};
