import omit from "lodash/omit";
import { AxiosError } from "axios";

import axios from "src/services/axios";
import firestore from "src/services/firestore";
import {
  triggerGtmEvent,
  isErrorTypeGuard,
  showDevelopmentError,
} from "src/utils";
import { getTimestamps } from "../utils";
import { API_ENDPOINTS, COLLECTION_IDS } from "../constants";

// Inner imports;
import * as schemas from "./dashboardsSchema";

export const getAllDashboards = async (
  companyId: Company.Data["id"],
): Promise<Dashboard.Data[]> => {
  const collection = await firestore()
    .collection(COLLECTION_IDS.dashboards)
    .where("companyId", "==", companyId)
    .get();

  const dashboards = new Set<Dashboard.Data>();

  for (const doc of collection.docs) {
    try {
      const dashboard = schemas.dashboardSchema.validateSync(doc.data());

      dashboards.add({ ...dashboard, id: doc.id });
    } catch (error) {
      let message = "";

      if (error instanceof AxiosError) message = error.response?.data?.error;

      if (isErrorTypeGuard(error)) message = error.message;

      const errorTitle = `DASHBOARD VALIDATION ERROR. DASHBOARD [${doc.id}]`;

      showDevelopmentError({
        error,
        additionalTexts: [errorTitle],
        reportMetadata: {
          message: `[${doc.id}]: Error at dashboard: ${message}`,
        },
      });
    }
  }

  return [...dashboards];
};

export const getDashboardById = async (
  dashboardId: Company.Data["id"],
): Promise<Dashboard.Data> => {
  const doc = await firestore()
    .collection(COLLECTION_IDS.dashboards)
    .doc(dashboardId)
    .get();

  if (!doc.exists) throw Error("Dashboard not found");

  const dashboard = schemas.dashboardSchema.validateSync(doc.data());

  return { ...dashboard, id: doc.id };
};

export const getSuggestedDashboards = async ({
  whiteLabel,
  userId,
  companyId,
  searchQuery,
  excludedDashboardIds,
  limit,
}: {
  whiteLabel: string;
  userId: User.Data["id"];
  companyId: Company.Data["id"];
  searchQuery: string;
  excludedDashboardIds: Dashboard.Data["id"][];
  limit: number;
}): Promise<Dashboard.SuggestedDashboard[]> => {
  const response = await axios.post(API_ENDPOINTS.getSuggestedDashboards, {
    limit,
    companyId,
    whiteLabel,
    searchQuery,
    authorId: userId,
    excludedDashboardIds,
  });

  const suggestedDashboards = new Set<Dashboard.SuggestedDashboard>();

  for (const suggestedDashboard of response.data) {
    const dashboardId = suggestedDashboard?.dashboardId;

    if (!dashboardId) continue;

    try {
      const validatedDashboard =
        schemas.suggestedDashboardSchema.validateSync(suggestedDashboard);

      suggestedDashboards.add(validatedDashboard);
    } catch (error) {
      let message = "";

      if (error instanceof AxiosError) message = error.response?.data?.error;

      if (isErrorTypeGuard(error)) message = error.message;

      const errorTitle = `SUGGESTED DASHBOARD VALIDATION ERROR. DASHBOARD [${dashboardId}]`;

      showDevelopmentError({
        error,
        additionalTexts: [errorTitle],
        reportMetadata: {
          message: `[${dashboardId}]: Error at suggested dashboard: ${message}`,
        },
      });
    }
  }

  return [...suggestedDashboards];
};

export const createDashboard = async (
  payload: Store.CreateEntity<Dashboard.Data>,
): Promise<Dashboard.Data> => {
  const { createdAt, updatedAt } = getTimestamps();

  const dashboardId = payload.trackersCollectionId;

  const _payload = {
    ...payload,
    createdAt,
    updatedAt,
  };

  await firestore()
    .collection(COLLECTION_IDS.dashboards)
    .doc(dashboardId)
    .set(_payload);

  triggerUpsertDashboardVector(dashboardId).catch(console.error);

  triggerGtmEvent("DashboardCreate", { dashboardId });

  return {
    ..._payload,
    id: dashboardId,
  };
};

export const createDashboardWithTrackers = async ({
  userId,
  trackers,
  category,
  companyId,
}: {
  userId: User.Data["id"];
  companyId: Company.Data["id"];
  category: Tracker.CategoryEntity;
  trackers: Array<Tracker.CreationData & Pick<Tracker.Data, "searchIds">>;
}): Promise<schemas.CreateDashboardWithTrackersSchemaType> => {
  const formattedTrackers = trackers.map((tracker) =>
    omit(tracker, "locationId", "languageId", "id"),
  );

  const response = await axios.post(API_ENDPOINTS.createDashboardWithTrackers, {
    companyId,
    authorId: userId,
    createDashboard: true,
    trackers: formattedTrackers,
    dashboardName: category.subject,
    dashboardDescription: category.description,
  });

  return schemas.createDashboardWithTrackersSchema.validateSync(response.data);
};

export const updateDashboard = async ({
  id,
  changes,
}: Store.UpdateEntity<Dashboard.Data>): Promise<
  Store.UpdateEntity<Dashboard.Data>
> => {
  const { updatedAt } = getTimestamps();

  const _changes = { ...changes, updatedAt };

  await firestore()
    .collection(COLLECTION_IDS.dashboards)
    .doc(id)
    .update(_changes);

  if (getShouldUpsertDashboardVectorTrigger(_changes))
    triggerUpsertDashboardVector(id).catch(console.error);

  return { id, changes: _changes };
};

export const updateDashboards = async (
  payload: Store.UpdateEntity<Dashboard.Data>[],
): Promise<Store.UpdateEntity<Dashboard.Data>[]> => {
  const { updatedAt } = getTimestamps();

  const updatedPayload: Store.UpdateEntity<Dashboard.Data>[] = [];

  const triggerUpsertDashboardVectorPromises: Promise<void>[] = [];

  const batch = firestore().batch();

  for (const { id, changes } of payload) {
    const _changes = { ...changes, updatedAt };

    const docRef = firestore().collection(COLLECTION_IDS.dashboards).doc(id);

    batch.set(docRef, _changes, { merge: true });

    updatedPayload.push({ id, changes: _changes });

    if (getShouldUpsertDashboardVectorTrigger(_changes))
      triggerUpsertDashboardVectorPromises.push(
        triggerUpsertDashboardVector(id),
      );
  }

  await batch.commit();

  Promise.all(triggerUpsertDashboardVectorPromises).catch(console.error);

  return updatedPayload;
};

export const updateDashboardsByAuthorId = async (
  {
    changes,
    authorId,
  }: {
    changes: Store.UpdateEntity<Dashboard.Data>["changes"];
    authorId: Dashboard.Data["authorId"];
  },
  companyId: Company.Data["id"],
): Promise<Store.UpdateEntity<Dashboard.Data>[]> => {
  const { updatedAt } = getTimestamps();

  const updatedPayload: Store.UpdateEntity<Dashboard.Data>[] = [];

  const triggerUpsertDashboardVectorPromises: Promise<void>[] = [];

  const batch = firestore().batch();

  const dashboardsByAuthorId = await firestore()
    .collection(COLLECTION_IDS.dashboards)
    .where("companyId", "==", companyId)
    .where("authorId", "==", authorId)
    .get();

  for (const { id, ref } of dashboardsByAuthorId.docs) {
    const _changes = { ...changes, updatedAt };

    batch.set(ref, _changes, { merge: true });

    updatedPayload.push({ id, changes: _changes });

    if (getShouldUpsertDashboardVectorTrigger(_changes))
      triggerUpsertDashboardVectorPromises.push(
        triggerUpsertDashboardVector(id),
      );
  }

  await batch.commit();

  Promise.all(triggerUpsertDashboardVectorPromises).catch(console.error);

  return updatedPayload;
};

export const getDashboardDataById = async (
  dashboardId: Dashboard.Data["id"],
): Promise<{
  eventIds: Event.Data["id"][];
  trackerIds: Tracker.Data["id"][];
  dashboardDateRangeIds: DashboardDateRange.Data["id"][];
}> => {
  const response = await axios.post(API_ENDPOINTS.getDashboardData, {
    dashboardId,
  });

  return schemas.getDashboardDataSchema.validateSync(response.data);
};

export const deleteDashboard = (id: Dashboard.Data["id"]): Promise<void> =>
  firestore().collection(COLLECTION_IDS.dashboards).doc(id).delete();

export const deleteDashboards = (
  dashboardIds: Dashboard.Data["id"][],
): Promise<void> => {
  const batch = firestore().batch();

  for (const dashboardId of dashboardIds) {
    const docRef = firestore()
      .collection(COLLECTION_IDS.dashboards)
      .doc(dashboardId);

    batch.delete(docRef);
  }

  return batch.commit();
};

export const subscribeOnIsDashboardCalculating = (
  trackersCollectionId: TrackersCollection.Data["id"],
  callback: (calculatingWidgetsQuantity: number) => void,
): (() => void) => {
  const docsRef = firestore()
    .collection(COLLECTION_IDS.widgetsSyncs)
    .where("trackersCollectionId", "==", trackersCollectionId);

  return docsRef.onSnapshot((snap) => {
    callback(snap.docs.length);
  });
};

export const generateDashboardShareLinkToken = async (payload: {
  isUpdateAllowed: boolean;
  dashboardId: Dashboard.Data["id"];
  dashboardDateRangeId: DashboardDateRange.Data["id"];
}): Promise<string> => {
  const { data } = await axios.post(API_ENDPOINTS.encodeShareLinkData, payload);

  return schemas.generateDashboardShareLinkTokenSchema.validateSync(data).token;
};

export const parseDashboardShareLinkToken = async (
  token: string,
): Promise<{
  isUpdateAllowed: boolean;
  dashboardId: Dashboard.Data["id"];
  dashboardDateRangeId: DashboardDateRange.Data["id"];
}> => {
  const { data } = await axios.post(API_ENDPOINTS.decodeShareLinkData, {
    token,
  });

  return schemas.parseDashboardShareLinkTokenSchema.validateSync(data);
};

export async function triggerUpsertDashboardVector(
  dashboardId: Dashboard.Data["id"],
): Promise<void> {
  try {
    await axios.post(API_ENDPOINTS.triggerUpsertDashboardVector, {
      dashboardId,
    });
  } catch (error) {
    const errorTitle = "UPSERT DASHBOARD VECTOR ERROR";

    showDevelopmentError({ additionalTexts: [errorTitle], error });
  }
}

function getShouldUpsertDashboardVectorTrigger(
  dashboardChanges:
    | Store.CreateEntity<Dashboard.Data>
    | Store.UpdateEntity<Dashboard.Data>["changes"],
): boolean {
  return "name" in dashboardChanges || "visibility" in dashboardChanges;
}
