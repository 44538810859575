import { SEARCH_DEFAULT_KEYWORDS_DATA_SOURCE } from "./searches";

export const DEFAULT_TRACKER_CATEGORY: Tracker.Data["category"] = "topic";

export const DEFAULT_TRACKER_DESCRIPTION: Tracker.Data["description"] = "";

export const TRACKER_KEYWORD_CATEGORY: string = "Keyword";

export const TRACKER_SORT_OPTIONS: Option[] = [
  { value: "name", label: "tracker.label.sort_name" },
  { value: "createdAt", label: "tracker.label.sort_created_at" },
  { value: "lastViewed", label: "tracker.label.last_viewed" },
];

export const TRACKER_INPUT_LIMIT: number = 150;

export const TRACKER_DEFAULT_KEYWORDS_DATA_SOURCES: Tracker.Data["keywordsDataSources"] =
  [SEARCH_DEFAULT_KEYWORDS_DATA_SOURCE];

export const TRACKER_CATEGORY_ICON: Record<string, AppIcon> = {
  topic: "Eye",
  brand: "TagOutline",
  product: "PackageOutline",
  destination: "LocationOutline",
  person: "PersonOutline",
  company: "StoreOutline",
  keyword: "SpaceBarOutline",
  default: "SpaceBarOutline",
  trend: "TrendUpOutline",
};
